import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };


export const createSpecificPaperAPI = async (param) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/exam-paper/`,
    method: 'POST',
    data: JSON.stringify({ ...param }),
    headers: headersList,
  };

  const response = await axios.request(reqOptions);
  return response;
};

export const registerSpecialPaperUserAPI = async (param) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/register-exam-user/`,
    method: 'POST',
    data: JSON.stringify({ ...param }),
    headers: headersList,
  };

  const response = await axios.request(reqOptions);
  return response;
};

export const verifySpecialPaperUserAPI = async (param) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/verify-exam-user-otp/`,
    method: 'POST',
    data: JSON.stringify({ ...param }),
    headers: headersList,
  };

  const response = await axios.request(reqOptions);
  return response;
};

export const retrieveSpecialPaperMetaDataAPI = async (id) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/exam-institute-info/?exam_id=${id}`,
    method: 'GET',
    headers: headersList,
  };
  const response = await axios.request(reqOptions);
  return response;
};


export const submitSpecificPaperAPI = async (param) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/exam-attempt/`,
    method: 'POST',
    data: JSON.stringify({ ...param }),
    headers: headersList,
  };
  const response = await axios.request(reqOptions);
  return response;
};

export const getSpecialPaperListAPI = async (url) => {
  //
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/exam/`);
  return response;
};
export const LeaderBoardSpecialAPI = async (url) => {
  const response = await axios.get(url);
  return response;
};

export const ExicelDownloadAPI = async (url) => {
  const response = await axios.get(url);
  return response;
};

