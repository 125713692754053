
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

export const getTournamentQuestions = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/question`);
    return response;
};

export const createTournamentGeneratorAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/tournament-papers/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };
    const response = axios.request(reqOptions);
    return response;
};

export const getTournamentListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/tournament-papers/`);
    return response;
};

export const getAttemptedTournamentListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/attempted-tournament/`);
    return response;
};

export const getTournamentAttemptListAPI = async (id) => {
    const response = await axios.get(`${REACT_APP_BACKEND_URL}/user-tournament-attempts/?tournament_id=${id}`);
    return response;
};

export const StartTournamentAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/start-tournament/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };
    const response = axios.request(reqOptions);
    return response;
};


export const retrieveTournamentPaperAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/tournament-papers/${id}/`,
        method: 'GET',
        headers: headersList,
    };
    const response = await axios.request(reqOptions);
    return response;
};

export const TournamentCashDistributeAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/tournament-reward-distributions/?tournament_id=${id}`,
        method: 'GET',
        headers: headersList,
    };
    const response = await axios.request(reqOptions);
    return response;
};

export const TournamentRewardCashDistributeAPI = async (url) => {
    const response = await axios.get(url);
    return response;
};


export const updateTournamentGeneratorAPI = async (param) => {
    const { id, values } = param;
    console.log("id", id)
    console.log("values", values)

    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/tournament-papers/${id}/`,
        method: 'PATCH',
        headers: headersList,
        data: JSON.stringify({ ...values }),
    };
    const response = axios.request(reqOptions);
    return response;
};