import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

/**
 * The function is an asynchronous API call to retrieve a Institute list based on the given parameter.
 *
 * Args:
 *   param: The `param` parameter is an object that contains any additional parameters or filters that you want to pass to
 * the API endpoint.
 */
export const getInstituteListAPI = async (url) => {
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/institute/`);
  return response;
};

/**
 * The function retrieves a Institute API based on the provided ID.
 */
export const retrieveInstituteAPI = async (id) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/institute/${id}/`,
    method: 'GET',
    headers: headersList,
  };
  const response = await axios.request(reqOptions);
  return response;
};

/**
 * The function creates a Institute API using the provided parameter.
 *
 * Args:
 *   param: The `param` parameter is an object that contains the necessary information to create a Institute API.
 */
export const createInstituteAPI = async (param) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/institute/`,
    method: 'POST',
    headers: headersList,
    data: JSON.stringify({ ...param }),
  };

  const response = await axios.request(reqOptions);
  return response;
};

/**
 * The function "updateInstituteAPI" is an asynchronous function that takes a parameter and performs some action.
 *
 * Args:
 *   param: The `param` parameter is an object that contains the necessary information to update a Institute API.
 */
export const updateInstituteAPI = async (param) => {
  const { id, values } = param;
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/institute/${id}/`,
    method: 'PATCH',
    headers: headersList,
    data: JSON.stringify({ ...values }),
  };

  return await axios.request(reqOptions);
};

/**
 * The deleteInstituteAPI function is an asynchronous function that takes an object with an "id" property as a parameter and
 * deletes the corresponding data from the API.
 */
export const deleteInstituteAPI = async (param) => {
  const { id } = param;
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/institute/${id}/`,
    method: 'DELETE',
    headers: headersList,
  };
  return await axios.request(reqOptions);
};
