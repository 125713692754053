import axios from '../../../utils/axios'
import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    currentQuestion: 1,
    workSheet: [],

}

export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setWorkSheet: (state, action) => {
            state.workSheet = action.payload
        },
        setQuestionSate: (state, action) => {
            state.questions = state.questions.map((question) =>
                question.id === action.payload.id
                    ? {
                        ...question,
                        state: action.payload.state,
                    }
                    : question,
            );
        },
    },
})

// Action creators are generated for each case reducer function
export const { setWorkSheet, setQuestionSate } = counterSlice.actions

export const fetchWorkSheet = () => async (dispatch) => {
    try {
        const response = await axios.get('/api/work-sheet');
        dispatch(setWorkSheet(response.data));
    } catch (err) {
        throw new Error();
    }
};

export default counterSlice.reducer