import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

export const getPriceRequestListAPI = async (url) => {
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/cash-redeem/`);
  return response;
};

export const updatePriceRequestAPI = async (param) => {
  const { id, values } = param;
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/cash-redeem/${id}/`,
    method: 'PATCH',
    headers: headersList,
    data: JSON.stringify({ ...values }),
  };

  return await axios.request(reqOptions);
};

