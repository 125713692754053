import { createSlice } from '@reduxjs/toolkit';
import {
    createQuestionNatureAPI,
    deleteQuestionNatureAPI,
    getQuestionNatureListAPI,
    retrieveQuestionNatureAPI,
    updateQuestionNatureAPI,
} from 'src/service/QuestionNatureService.js';

const initialState = {
    createModalOpen: false,
    allQuestionNature: [],
    validationErrors: {},
};

export const QuestionNatureManager = createSlice({
    name: 'QuestionNature',
    initialState,
    reducers: {
        openCreateModal: (state) => {
            state.createModalOpen = true;
        },
        closeCreateModal: (state) => {
            state.createModalOpen = false;
        },
        setAllQuestionNature: (state, action) => {
            state.allQuestionNature = action.payload;
        },
        setValidationErrors: (state, action) => {
            state.validationErrors = action.payload;
        },
        clearValidationErrors: (state) => {
            state.validationErrors = {};
        },
        addQuestionNature: (state, action) => {
            state.allQuestionNature = [action.payload, ...state.allQuestionNature];
        },
        updateQuestionNature: (state, action) => {
            // Below code is to update the question in the array of questions
            const index = state.allQuestionNature.findIndex((obj) => obj.id === action.payload?.data?.id);
            state.allQuestionNature = [...state.allQuestionNature.slice(0, index), action.payload?.data, ...state.allQuestionNature.slice(index + 1)];
        },

        deleteQuestionNature: (state, action) => {
            const index = action.payload;
            state.allQuestionNature.splice(index, 1);
        },
    },
});

export const {
    openCreateModal,
    closeCreateModal,
    setAllQuestionNature,
    setValidationErrors,
    clearValidationErrors,
    addQuestionNature,
    updateQuestionNature,
    deleteQuestionNature,
} = QuestionNatureManager.actions;

export const getQuestionNatureListAction = (param) => async (dispatch) => getQuestionNatureListAPI(param);

export const retrieveQuestionNatureAction = (id) => async (dispatch) => retrieveQuestionNatureAPI(id);

export const createQuestionNatureAction = (param) => async (dispatch) => createQuestionNatureAPI(param);

export const updateQuestionNatureAction = (param) => async (dispatch) => updateQuestionNatureAPI(param);

export const deleteQuestionNatureAction = (id) => async (dispatch) => deleteQuestionNatureAPI(id);

export default QuestionNatureManager.reducer;
