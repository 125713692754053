import { createSlice } from '@reduxjs/toolkit';
import {
	createInstituteAPI,
	deleteInstituteAPI,
	getInstituteListAPI,
	retrieveInstituteAPI,
	updateInstituteAPI,
} from 'src/service/InstituteService.js';

const initialState = {
	createModalOpen: false,
	allInstitutes: [],
	validationErrors: {},
};

export const Institute = createSlice({
	name: 'Institute',
	initialState,
	reducers: {
		openCreateModal: (state) => {
			state.createModalOpen = true;
		},
		closeCreateModal: (state) => {
			state.createModalOpen = false;
		},
		setAllInstitutes: (state, action) => {
			state.allInstitutes = action.payload;
		},
		setValidationErrors: (state, action) => {
			state.validationErrors = action.payload;
		},
		clearValidationErrors: (state) => {
			state.validationErrors = {};
		},
		addInstitute: (state, action) => {
			state.allInstitutes.push(action.payload);
		},
		updateInstitute: (state, action) => {
			const { index, values } = action.payload;
			state.allInstitutes[index] = { ...state.allInstitutes[index], ...values };
		},
		deleteInstitute: (state, action) => {
			const index = action.payload;
			state.allInstitutes.splice(index, 1);
		},
	},
});

export const {
	openCreateModal,
	closeCreateModal,
	setAllInstitutes,
	setValidationErrors,
	clearValidationErrors,
	addInstitute,
	updateInstitute,
	deleteInstitute,
} = Institute.actions;

export const getInstituteListAction = (param) => async (dispatch) => getInstituteListAPI(param);

export const retrieveInstituteAction = (id) => async (dispatch) => retrieveInstituteAPI(id);

export const createInstituteAction = (param) => async (dispatch) => createInstituteAPI(param);

export const updateInstituteAction = (param) => async (dispatch) => updateInstituteAPI(param);

export const deleteInstituteAction = (id) => async (dispatch) => deleteInstituteAPI(id);

export default Institute.reducer;
