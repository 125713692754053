import { createSlice } from '@reduxjs/toolkit';
import { createSectionAPI, deleteSectionAPI, getSectionListAPI, retrieveSectionAPI, updateSectionAPI } from 'src/service/SectionService';


const initialState = {
    paperData: {},
    paperQuestions: [],
    Sections: [],
    water_mark: {
        text: '',
        url: '',
        font_size: 12,
    },
    // water_mark: {
    //     text: "",
    //     url: "",
    //     font_size: 12,
    //     font_color: "rgb(0 0 0 / 25%)",
    //     fontStyle: "normal",
    //     fontWeight: "normal",
    //     fontFamily: "Arial",
    //     rotate: 0,
    //     width: 50,
    //     height: 20,
    //     gapx: 50,
    //     gapy: 20,
    // },
    logo: {
        logo_url: "",
        position: "R",
    },  //logo: {url: abc, location: R/L/C}
    header: {
        title: "",
        header: "",
        sub_header: "",
        standards: "",
        subject: "",
    },
    studentSection: {
        class: true,
        paperCode: true,
        rollNumber: true,
        section: true,
        studentName: true,
        subject: true,
        maximumMarks: true,
        maximumTime: true,
        headerInstruction: true,
        footerInstruction: true,
    },
    page_formatting: {
        orientation: "portrait",
        paper_size: "A4",
        margin: {
            top: 20,
            bottom: 20,
            left: 20,
            right: 20,
        },
        header: {
            height: 0,
        },
        footer: {
            height: 0,
        },
        font_size: 20,
        font_family: "Arial",
    },
    Section: {

    },

    headerInstruction: {},
    footerInstruction: {},

};
const updateSections = (state) => {
    state.Sections.forEach((section) => {
        let oldQuestion_paper_questions = state.paperData.question_paper_questions
        oldQuestion_paper_questions[section.start_id - 1] = { ...oldQuestion_paper_questions[section.start_id - 1], section }
        state.paperData = { ...state.paperData, question_paper_questions: oldQuestion_paper_questions }

    })
    state.paperQuestions = state.paperData.question_paper_questions
}

const PaperEditorReducer = createSlice({

    name: "paperEditor",
    initialState: initialState,
    reducers: {

        setWorksheetData: (state, action) => {
            const { paper_formatting, title, standards, subjects } = action.payload
            state.header = { ...state.header, title, standard: standards?.map(item => item.display_name).join(", "), subject: subjects?.map(item => item.display_name).join(", "), ...paper_formatting?.header || {} }
            state.logo = { ...state.logo, ...paper_formatting?.logo || {} }
            state.water_mark = paper_formatting?.water_mark
            state.studentSection = { ...state.studentSection, ...paper_formatting?.studentSection || {} }
            state.page_formatting = { ...state.page_formatting, ...paper_formatting?.page_formatting || {} }
            state.paperData = action.payload
            state.paperQuestions = action.payload?.question_paper_questions || []
            action.payload?.instructions?.map((instruction) => {
                if (instruction.type === "header") {
                    state.headerInstruction = instruction
                } else if (instruction.type === "footer") {
                    state.footerInstruction = instruction
                }
            })
            updateSections(state)
        },
        setStudentSection: (state, action) => {
            state.studentSection = { ...state.studentSection, ...action.payload };
        },
        setWaterMark: (state, action) => {
            state.water_mark = { ...state.water_mark, ...action.payload };
        },
        setPaperHeader: (state, action) => {
            state.header = { ...state.header, ...action.payload };
        },
        setPageFormat: (state, action) => {
            state.page_formatting = { ...state.page_formatting, ...action.payload };
        },
        setHeaderInstruction: (state, action) => {
            state.headerInstruction = { ...state.headerInstruction, ...action.payload };
        },
        setFooterInstruction: (state, action) => {
            state.footerInstruction = { ...state.footerInstruction, ...action.payload };
        },
        setLogo: (state, action) => {

            state.logo = { ...state.logo, ...action.payload };
        },
        setPaperSection: (state, action) => {
            state.Sections = action.payload;
            updateSections(state)
        },

    }
});

export const getSectionListAction = (param) => async (dispatch) => getSectionListAPI(param);

export const retrieveSectionAction = (id) => async (dispatch) => retrieveSectionAPI(id);

export const createSectionAction = (param) => async (dispatch) => createSectionAPI(param);

export const updateSectionAction = (param) => async (dispatch) => updateSectionAPI(param);

export const deleteSectionAction = (id) => async (dispatch) => deleteSectionAPI(id);

export const {
    setLogo,
    setPageFormat,
    setHeaderInstruction,
    setFooterInstruction,
    setWorksheetData,
    setPaperSection,
    setPaperTitle,
    setPaperHeader,
    setPaperSubHeader,
    setStudentSection,
    setWaterMark
} = PaperEditorReducer.actions;

export default PaperEditorReducer.reducer;


