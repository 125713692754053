import axios from 'axios';
import { useSnackbar } from 'notistack';
import { createContext, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ErrorNotification } from 'src/cutomHooks/userTableTheme';
import { getUserData, isValidToken, setSession } from 'src/service/AuthService';
import { otpSendSuccess, setEmail, setRequestType, setUserData } from 'src/store/AuthReducer';
import { setAllTheme } from 'src/store/theme/ThemeSlice';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
import { updateUserAPI } from '../../service/UserManagerService';
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  features: [],
  permissions: [],
  isStudent: true,
  isAdmin: false,
  userType: "Student",
  avatar: "",
  instituteLogo: "",
  cash: 0,
  coins: 0,
  vpa: ""
};
const userType = {
  0: "Teacher",
  1: "Student",
  2: "Staff",
  4: "Parent"
}


const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      features: user?.features?.map((feature) => feature.name),
      permissions: user?.permissions?.map((permission) => permission.name),
      isStudent: user?.user_type === 1 ? true : false,
      isAdmin: user?.is_admin,
      userType: userType[user?.user_type],
      avatar: user?.avatar,
      instituteLogo: user?.institute?.logo,
      coins: user?.credit?.credits,
      cash: user?.cash?.amount,
      vpa: user?.profile?.vpa
    };
  },
  AVATAR: (state, action) => {
    const { avatar } = action.payload;

    return {
      ...state,
      avatar: avatar,
    };
  },
  INSTITUTE_LOGO: (state, action) => {
    const { logo } = action.payload;

    return {
      ...state,
      instituteLogo: logo,
    };
  },

  CASH: (state, action) => {
    const { cash } = action.payload;

    return {
      ...state,
      cash: cash
    };
  },

  COINS: (state, action) => {
    const { coins } = action.payload;

    return {
      ...state,
      coins: coins
    };
  },

  UPI: (state, action) => {
    const { vpa } = action.payload;

    return {
      ...state,
      vpa: vpa
    };
  },

  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      features: user?.features?.map((feature) => feature.name),
      permissions: user?.permissions?.map((permission) => permission.name),
      isStudent: user?.user_type === 1 ? true : false,
      isAdmin: user?.is_admin,
      userType: userType[user?.user_type],
      avatar: user?.avatar,
      institute_logo: user?.institute?.logo,
      coins: user?.credit?.credits,
      cash: user?.cash?.amount,
      vpa: user?.profile?.vpa
    };
  },
  LOGOUT: (state) => ({
    ...state,
    ...initialState
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      features: user?.features?.map((feature) => feature.name),
      permissions: user?.permissions?.map((permission) => permission.name),
      isStudent: user?.user_type === 1 ? true : false,
      isAdmin: user?.is_admin,
      userType: userType[user?.user_type],
      coins: user?.credit?.credits,
      cash: user?.cash?.amount,
      vpa: user?.profile?.vpa
    };
  },

};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  signup: () => Promise.resolve(),
  verifyOTP: () => Promise.resolve(),
  signin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateUserLogo: () => Promise.resolve(),
  updateInstituteLogo: () => Promise.resolve(),
  updateUserProfile: () => Promise.resolve(),
  updateCoins: () => Promise.resolve(),
  updateCash: () => Promise.resolve(),
  updateUPI: () => Promise.resolve(),
});

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    const initialize = async () => {
      const userData = JSON.parse(window.localStorage.getItem('userData'));
      await isValidToken(userData?.access)
        .then((response) => {
          if (response.status === 200) {
            setSession(userData);
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
              },
            });
          } else {
            return
          }

          getUserData(userData?.access)
            .then((response) => {
              const { data } = response;
              dispatcher(setUserData(response.data?.data))
              setSession({ ...userData, ...data?.data });
              if (response.data?.data?.settings?.theme) {
                dispatcher(setAllTheme(response.data?.data?.settings?.theme));
              }
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: true,
                  user: data?.data,
                },
              });
            })
            .catch((error) => {
              // getUserData's Catch

              if (error.response?.status === 401) {
                enqueueSnackbar("Session expired. Please login again.", { variant: 'warning', autoHideDuration: 5000 })
                logout()
                return
              }
              ErrorNotification(error.response)
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: false,
                  user: null,
                },
              });
            });
        })
        .catch((error) => {
          // isValidToken's Catch
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
          setSession(null)
          enqueueSnackbar('Un-Authorized Access.', { variant: 'warning', autoHideDuration: 3000 })
        });
    };
    initialize();
  }, []);

  const signin = async (email) => {
    await axios
      .post(`${REACT_APP_BACKEND_URL}/auth/otp/generate-otp/`, {
        phone_number_or_email: email,
      })
      .then((response) => {
        dispatcher(otpSendSuccess(response.data?.data));
        dispatcher(setEmail(email));
        navigate('/auth/otp-validation', { replace: true });
      }).catch((error) => {
        const { data } = error.response?.data;
        const { message = "Something went wrong." } = data;
        enqueueSnackbar(message, { variant: 'error', autoHideDuration: 3000 })
      });
  };

  const verifyOTP = async (pram) => {
    try {
      const response = await axios.post(`${REACT_APP_BACKEND_URL}/auth/otp/verify-otp/`, pram);
      const data = response.data?.data;

      dispatch(setRequestType(3));

      setSession(data);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: data,
        },
      });


      if (data.is_active) {
        if (data?.settings?.theme) {
          dispatcher(setAllTheme(data?.settings?.theme));
        }
        navigate('/', { replace: true }); // Navigate to home screen
      } else {
        navigate('/404', { replace: true }); // Navigate to another screen
      }
    } catch (error) {
      const { data } = error.response?.data;
      const { message = "Something went wrong." } = data;
      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 3000 });
    }
  };

  const signup = async (pram) => {
    await axios.post(`${REACT_APP_BACKEND_URL}/auth/otp/sign-up/`, pram)
      .then((response) => {
        dispatcher(otpSendSuccess(response.data?.data));
        dispatcher(setEmail(pram.phone_number_or_email));
        navigate('/auth/otp-validation', { replace: true });
        localStorage.setItem('isFirstIn', true);

      }).catch((error) => {
        const { data } = error.response?.data;
        const { message = "Something went wrong." } = data;
        enqueueSnackbar(message, { variant: 'error', autoHideDuration: 3000 })
      });
  };

  const updateUserProfile = async (param) => {
    await updateUserAPI(param).then((response) => {
      dispatch({
        type: 'LOGIN',
        payload: {
          user: response.data?.data,
        },
      });

      dispatcher(setUserData(response.data?.data))
      enqueueSnackbar('Your profile updated Successful!', { variant: 'success' });

    }).catch((error) => {
      const { data } = error.response?.data;
      const { message = "Something went wrong." } = data;
      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 3000 })
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('isFirstIn');
    navigate('/auth/login', { replace: true });
  };

  const updateUserLogo = async (file) => {
    const formData = new FormData();
    formData.append('avatar', file);

    try {
      const response = await axios.patch(`${REACT_APP_BACKEND_URL}/upload-user-avatar/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({
        type: 'AVATAR',
        payload: {
          avatar: response.data?.data?.avatar,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const updateInstituteLogo = async (file) => {
    const formData = new FormData();
    formData.append('logo', file);
    try {
      const response = await axios.patch(`${REACT_APP_BACKEND_URL}/upload-institute-logo/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({
        type: 'INSTITUTE_LOGO',
        payload: {
          logo: response.data?.data?.logo,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const updateCash = async (cash) => {
    dispatch({
      type: 'CASH',
      payload: {
        cash: cash
      }
    });
  };

  const updateUPI = async (vpa) => {
    dispatch({
      type: 'UPI',
      payload: {
        vpa: vpa
      }
    });
  };

  const updateCoins = async (coins) => {
    dispatch({
      type: 'COINS',
      payload: {
        coins: coins
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signin,
        logout,
        signup,
        verifyOTP,
        updateCash,
        updateUPI,
        updateCoins,
        updateUserLogo,
        updateInstituteLogo,
        updateUserProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

