import { CssBaseline, ThemeProvider } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import ScrollToTop from './components/shared/ScrollToTop';
import RTL from './layouts/full/shared/customizer/RTL';
import Router from './routes/Router';
import { ThemeSettings } from './theme/Theme';

function App() {

  // const { user, features } = useAuth();
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.customizer);
  const routing = useRoutes(Router);
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  axios.defaults.headers.common.Authorization = `Bearer ${userData?.access}`;

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <ScrollToTop>{routing}</ScrollToTop>
      </RTL>
    </ThemeProvider>
  );
}

export default App;
