import { createSlice } from '@reduxjs/toolkit';
import { getPriceRequestListAPI, updatePriceRequestAPI } from 'src/service/PriceDistributionService';

const initialState = {
  createModalOpen: false,
  allPriceRequestList: [],
  validationErrors: {},
};

export const PriceDistributionManager = createSlice({
  name: 'PriceDistribution',
  initialState,
  reducers: {
    setAllPriceRequestList: (state, action) => {
      state.allPriceRequestList = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    updatePriceDistribution: (state, action) => {
      // Below code is to update the question in the array of questions
      const index = state.allPriceRequestList.findIndex((obj) => obj.id === action.payload?.id);
      state.allPriceRequestList = [...state.allPriceRequestList.slice(0, index), action.payload, ...state.allPriceRequestList.slice(index + 1)];
    },
  },
});

export const {
  setAllPriceRequestList,
  setValidationErrors,
  clearValidationErrors,
  updatePriceDistribution,
} = PriceDistributionManager.actions;

export const getPriceRequestListAction = (param) => async (dispatch) => getPriceRequestListAPI(param);

export const updatePriceRequestAction = (param) => async (dispatch) => updatePriceRequestAPI(param);

export default PriceDistributionManager.reducer;
