import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

export const getLeaderBoardListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/coin-leaderboard/`);
    return response;
};

export const getPaperLeaderBoardListAPI = async (param) => {
    const { id, url } = param
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/paper-leaderboard/${id}`);
    return response;
};

export const TournamentRegisterAPI = async (param) => {
    const { values } = param
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/api/test-registration/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...values }),
    };
    const response = await axios.request(reqOptions);
    return response;
};

export const PriceDistributionAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/distribute-class-test-rewards/?paper_id=${id}`,
        method: 'GET',
        headers: headersList,
    };

    const response = await axios.request(reqOptions);
    return response;
};

export const RedeemCoinsAPI = async (param) => {
    const { values } = param
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/coin_redeem/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...values }),
    };
    const response = await axios.request(reqOptions);
    return response;
};
