import { createSlice } from '@reduxjs/toolkit';
import { createBatchAPI, createBatchPaperAPI, createBatchRequestAPI, deleteBatchAPI, getBatchListAPI, getBatchNotesListAPI, getBatchPaperListAPI, getBatchRequestListAPI, getStudentRequestListAPI, partialUpdateBatchAPI, retrieveBatchAPI, updateBatchAPI, updateStudentRequest } from 'src/service/BatchModeService';

const initialState = {
    batchList: [],
    batchPaperList: [],
    batchRequestList: [],
    batchNotesList: [],
    validationErrors: {},
    loading: false,
    error: null,
};

export const BatchManager = createSlice({
    name: 'Batch',
    initialState,
    reducers: {
        setBatchList: (state, action) => {
            state.batchList = action.payload;
            state.loading = false;
            state.error = null;
        },
        setBatchNotesList: (state, action) => {
            state.batchNotesList = action.payload;
        },
        setValidationErrors: (state, action) => {
            state.validationErrors = action.payload;
            state.loading = false;
            state.error = null;
        },
        clearValidationErrors: (state) => {
            state.validationErrors = {};
        },
        addBatch: (state, action) => {
            state.batchList = [action.payload, ...state.batchList];
            state.loading = false;
            state.error = null;
        },
        updateBatch: (state, action) => {
            const index = state.batchList.findIndex((batch) => batch.id === action.payload.id);
            state.batchList = [...state.batchList.slice(0, index), action.payload, ...state.batchList.slice(index + 1)];
            state.loading = false;
            state.error = null;
        },
        deleteBatch: (state, action) => {
            const index = state.batchList.findIndex((batch) => batch.id === action.payload);
            state.batchList.splice(index, 1);
            state.loading = false;
            state.error = null;
        },
        setBatchPaperList: (state, action) => {
            state.batchPaperList = action.payload;
            state.loading = false;
            state.error = null;
        },
        addBatchPaper: (state, action) => {
            state.batchPaperList = [action.payload, ...state.batchPaperList];
            state.loading = false;
            state.error = null;
        },
        setBatchRequestList: (state, action) => {
            state.batchRequestList = action.payload;
            state.loading = false;
            state.error = null;
        },
        addBatchRequest: (state, action) => {
            state.batchRequestList = [action.payload, ...state.batchRequestList];
            state.loading = false;
            state.error = null;
        },
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    setBatchList,
    setValidationErrors,
    clearValidationErrors,
    addBatch,
    updateBatch,
    deleteBatch,
    setBatchPaperList,
    addBatchPaper,
    setBatchRequestList,
    addBatchRequest,
    setLoading,
    setError,
    setBatchNotesList,
} = BatchManager.actions;



export const getBatchListAction = () => async (dispatch) => {
    try {
        dispatch(setLoading());

        const response = await getBatchListAPI();


        dispatch(setBatchList(response.data));
        return response;
    } catch (error) {
        console.error('Error in action creator:', error);
        dispatch(setError(error.response?.data?.data?.message || 'Unknown error'));
        throw error;
    }
};




export const retrieveBatchAction = (id) => async (dispatch) => retrieveBatchAPI(id);
export const getBatchAction = (id) => async (dispatch) => getBatchListAPI(id);

export const createBatchAction = (param) => async (dispatch) => createBatchAPI(param);

export const updateBatchAction = (param) => async (dispatch) => updateBatchAPI(param);

export const partialUpdateBatchAction = (param) => async (dispatch) => partialUpdateBatchAPI(param);

export const deleteBatchAction = (id) => async (dispatch) => deleteBatchAPI(id);



export const getBatchPaperListAction = (id) => async (dispatch) => getBatchPaperListAPI(id);

export const createBatchPaperAction = (id, param) => async (dispatch) => createBatchPaperAPI(id, param);

export const getBatchRequestListAction = (id) => async (dispatch) => getBatchRequestListAPI(id);

export const createBatchRequestAction = (param) => async (dispatch) => createBatchRequestAPI(param);
export const getStudentRequestListAction = (id) => async (dispatch) => getStudentRequestListAPI(id);
export const updateStudentRequestAction = (param) => async (dispatch) => updateStudentRequest(param);

export const getBatchNotesListAction = (param) => async (dispatch) => getBatchNotesListAPI(param);

export default BatchManager.reducer;
