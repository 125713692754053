import { userTransactionForAdminAPI } from "src/service/AdminDashboardService";

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userTransactionList: [],
};

export const AdminManager = createSlice({
    name: 'Admin',
    initialState,
    reducers: {
        setUserTransactionList: (state, action) => {
            state.userTransactionList = action.payload;
        },
    }
})

export const {
    setUserTransactionList
} = AdminManager.actions;

export const userTransactionForAdminAction = (url) => async (dispatch) => userTransactionForAdminAPI(url);
export default AdminManager.reducer;