import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

export const getBullsAnalysisAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/api/bullseye/analysis?attempt_id=${id}`,
        method: 'GET',
        headers: headersList,
    };
    const response = await axios.request(reqOptions);
    return response;
};