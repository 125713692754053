
import { createSlice } from '@reduxjs/toolkit';
import { createKycAPI, getKycListAPI, updateKycAPI } from 'src/service/KycService';

const initialState = {
    isEditable: false,
    // ispending: false,
};

export const KYCReducer = createSlice({
    name: 'KYC',
    initialState,
    reducers: {
        setIsEditable: (state, action) => {
            state.isEditable = action.payload;
        },
        // setIsPending: (state, action) => {
        //     state.ispending = action.payload;
        // },
    },
});

export const {
    setIsEditable,
    // setIsPending
} = KYCReducer.actions;

export const getKycListAPIAction = (param) => async (dispatch) => getKycListAPI(param);
export const createKycAPIAction = (param) => async (dispatch) => createKycAPI(param);
export const updateKycAction = (param) => async (dispatch) => updateKycAPI(param);


export default KYCReducer.reducer;