import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

export const getTeacherNotesListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/notes/`);
    return response;
};

export const getPurchaseNotesListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/purchase-notes/`);
    return response;
};

export const getNoteAPI = async (id) => {
    const response = await axios.get(`${REACT_APP_BACKEND_URL}/notes/${id}`);
    return response;
};


export const purchaseNotesAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/purchase-notes/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };

    const response = await axios.request(reqOptions);
    return response;
};

export const uploadNotesAPI = async (formData) => {
    try {
        const response = await axios.post(`${REACT_APP_BACKEND_URL}/upload-notes/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const uploadNotesImageAPI = async (param) => {

    const formData = new FormData();
    formData.append('image', param);

    let reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/upload-image/`,
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    };
    const response = await axios.request(reqOptions);


    return response;
};


export const uploadFreeNotesAPI = async (formData) => {
    try {
        const response = await axios.post(`${REACT_APP_BACKEND_URL}/upload-free-notes/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const downloadFreeNotesAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/free-note-download/${id}/`,
        method: 'GET',
        headers: headersList,
    };
    const response = await axios.request(reqOptions);
    return response;
};

export const downloadPaidNotesAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}}/note-download/${id}/`,
        method: 'GET',
        headers: headersList,
    };
    const response = await axios.request(reqOptions);
    return response;
};



export const createNotesAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/notes/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };

    const response = await axios.request(reqOptions);
    return response;
};



export const updateNotesAPI = async (param) => {
    const { id, values } = param;
    let reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/notes/${id}/`,
        method: 'PATCH',
        headers: headersList,
        data: JSON.stringify({ ...values }),
    };

    return await axios.request(reqOptions);
};

export const deleteNotesAPI = async (param) => {
    const { id } = param;
    let reqOptions = {
        url: `${REACT_APP_BACKEND_URL}}/notes/${id}/`,
        method: 'DELETE',
        headers: headersList,
    };
    return await axios.request(reqOptions);
};
