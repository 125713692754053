import { createSlice } from '@reduxjs/toolkit';
import {
  createSourceAPI,
  deleteSourceAPI,
  getSourceListAPI,
  retrieveSourceAPI,
  updateSourceAPI,
} from 'src/service/SourceService.js';

const initialState = {
  createModalOpen: false,
  allSources: [],
  validationErrors: {},
};

export const SourceManger = createSlice({
  name: 'Source',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    setAllSources: (state, action) => {
      state.allSources = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addSource: (state, action) => {
      state.allSources = [action.payload, ...state.allSources];
    },
    updateSource: (state, action) => {
      // Below code is to update the question in the array of questions
      const index = state.allSources.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allSources = [...state.allSources.slice(0, index), action.payload?.data, ...state.allSources.slice(index + 1)];
    },
    deleteSource: (state, action) => {
      const index = action.payload;
      state.allSources.splice(index, 1);
    },
  },
});

export const {
  openCreateModal,
  closeCreateModal,
  setAllSources,
  setValidationErrors,
  clearValidationErrors,
  addSource,
  updateSource,
  deleteSource,
} = SourceManger.actions;

export const getSourceListAction = (param) => async (dispatch) => getSourceListAPI(param);

export const retrieveSourceAction = (id) => async (dispatch) => retrieveSourceAPI(id);

export const createSourceAction = (param) => async (dispatch) => createSourceAPI(param);

export const updateSourceAction = (param) => async (dispatch) => updateSourceAPI(param);

export const deleteSourceAction = (id) => async (dispatch) => deleteSourceAPI(id);

export default SourceManger.reducer;
