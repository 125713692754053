//import { AuthProvider } from 'src/guards/auth0/Auth0Context';
import './_mockApis';
import './utils/i18n';
import './index.css';

import { IconX } from '@tabler/icons-react';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import { AuthProvider } from 'src/guards/firebase/FirebaseContext';
import { AuthProvider } from 'src/guards/jwt/JwtContext';

import App from './App';
import { store } from './store/Store';
import Spinner from './views/template/spinner/Spinner';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <SnackbarProvider
          preventDuplicate
          autoHideDuration={5000}
          maxSnack={5}
          dense
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          action={(snackbarId) => <IconX onClick={() => closeSnackbar(snackbarId)}>X</IconX>}
        >
          <AuthProvider>
            <App />
          </AuthProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </Suspense>
  </Provider>
);
