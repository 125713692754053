import { createSlice } from '@reduxjs/toolkit';
import {
  createBoardAPI,
  deleteBoardAPI,
  getBoardListAPI,
  retrieveBoardAPI,
  updateBoardAPI,
} from 'src/service/BoardService.js';

const initialState = {
  createModalOpen: false,
  allBoards: [],
  validationErrors: {},
};

export const BoardManager = createSlice({
  name: 'Board',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    setAllBoards: (state, action) => {
      state.allBoards = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addBoard: (state, action) => {
      state.allBoards = [action.payload, ...state.allBoards];
    },
    updateBoard: (state, action) => {
      // Below code is to update the question in the array of questions
      const index = state.allBoards.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allBoards = [...state.allBoards.slice(0, index), action.payload?.data, ...state.allBoards.slice(index + 1)];
    },
    deleteBoard: (state, action) => {
      const index = action.payload;
      state.allBoards.splice(index, 1);
    },
  },
});

export const {
  openCreateModal,
  closeCreateModal,
  setAllBoards,
  setValidationErrors,
  clearValidationErrors,
  addBoard,
  updateBoard,
  deleteBoard,
} = BoardManager.actions;

export const getBoardListAction = (param) => async (dispatch) => getBoardListAPI(param);

export const retrieveBoardAction = (id) => async (dispatch) => retrieveBoardAPI(id);

export const createBoardAction = (param) => async (dispatch) => createBoardAPI(param);

export const updateBoardAction = (param) => async (dispatch) => updateBoardAPI(param);

export const deleteBoardAction = (id) => async (dispatch) => deleteBoardAPI(id);

export default BoardManager.reducer;
