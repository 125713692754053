import { createSlice } from '@reduxjs/toolkit';
// import { Colors } from '../../constants/styles';
const viewSpecificQuestion = (state, action) => {
    state.currentIndex = action.payload;
    const qState = state.questionList[action.payload]?.state === 0 ? 4 : state.questionList[action.payload]?.state
    state.questionList[action.payload] = { ...state.questionList[action.payload], state: qState }
}
const initialState = {
        ExamPaperData:{},
        paperData: {},
        questionList: [],
        isDrawerOpen: true,
        isInstructionVisible: false,
        isQuestionPaperVisible: false,
        currentIndex: 0,
        result: [],
        Section: [],
        SectionIndex: {
            SectionStartIndex: undefined,
            StartSectionName: "",
            SectionEndIndex: undefined,
            EndSectionName: "",
        },
        selectMultiOption:[],

        // 0: Not Visited, 1: Answered, 2:Marked 3:Marked and answered 4:Not Answered

        questionStateColor: {
            0: {
                color: '#000',
                background: '#fff',
            },
            1: {
                borderRadius: '20px 20px 0 0',
                background:'#00D209',
                color: '#fff',
                borderColor: '#00D209',
            },
            2: {
                background: '#FF9900',
                borderRadius: '24px',
                borderColor:'#FF9900',
                color: '#FF9900',
            },
            3: {
                background:'#BF80FF',
                borderRadius: '24px',
                borderColor:'#BF80FF',
                color: '#BF80FF',
                after: {
                    content: '',
                    position: 'absolute',
                    borderColor: '#27ae60',
                    borderWidth: 'solid',
                    border: '0 0.3em 0.3em 0',
                    height: ' 1em',
                    top: '-0.375em',
                    right: '0',
                    transform: 'rotate(45deg)',
                    width: '0.5em',
                }
            },
            4: {
                borderRadius: '0 0 20px 20px',
                background: '#E57373',
                borderColor:  '#E57373',
                color: '#E57373',
            }
        },

    
}

export const ExamFlowSlice=createSlice({
    name:'ExamFlow',
    initialState,
    reducers:{
        setExamPaperDataResponse:(state,action)=>{
            state.ExamPaperData=action?.payload
            state.questionList = action.payload?.question_paper_questions?.map(item => ({ ...item, state: 0,timeSpent:0 }))
            state.currentIndex=0
        },
        setPaperData: (state, action) => {
            state.paperData = action.payload
            // 0: Not Visited, 1: Answered, 2:Marked 3:Marked and answered 4:Not Answered
            state.questionList = action.payload?.question_paper_questions?.map(item => ({ ...item, state: 0, timeSpent: 0 }))
        },
        setPaperSection: (state, action) => {
            state.Section = action.payload.map(item => ({ ...item }))
        },
        setQuestionList: (state, action) => {
            state.questionList = action.payload
        },

        setQuestionAnswerSelection: (state, action) => {
            state.questionList[action.payload?.index] =
            {
                ...action.payload?.currentQuestion,
                state: 1,
                selectedOption: action.payload?.item 
            }
        },
        setMultiNotAnswerSate: (state, action) => {
            state.questionList[action.payload?.index] =
            {
                ...action.payload?.currentQuestion,
                state: 4,
                // selectedOption: action.payload?.item 
            }
        },
        setMultiQuestionAnswerSelection: (state, action) => {
                state.selectMultiOption= action.payload
        },
        setOrQuestionAnswerSelection: (state, action) => {
            const { index, currentQuestion, orIndex, orques, option } = action.payload;

            const updatedOrQuestions = currentQuestion.or_questions.map((orQuestion, i) => {
                if (i === orIndex) {
                    return {
                        ...orQuestion,
                        selectedOption: option
                    };
                }
                return {
                    ...orQuestion,
                    selectedOption: {} // Set selectedOption to an empty object for other or_questions
                };
            });

            state.questionList[index] = {
                ...currentQuestion,
                state: 1,
                selectedOption: {}, // Set selectedOption to an empty object for the main question
                or_questions: updatedOrQuestions,
            };
        },
        setClearResponse: (state, action) => {
            const { index, currentQuestion, } = action.payload;
            const updatedOrQuestions = currentQuestion.or_questions.map(orQuestion => ({
                ...orQuestion,
                selectedOption: {}  // Set selectedOption to an empty object
            }));
            state.questionList[index] = {
                ...currentQuestion,
                selectedOption: {},
                state: 4,
                or_questions: updatedOrQuestions
            }
            state.selectMultiOption=[]
        },
        setQuestionTime: (state, action) => {
            const t = action.payload.currentQuestion.timeSpent + action.payload?.time;
            state.questionList[action.payload?.index] = {
                ...action.payload?.currentQuestion,
                timeSpent: t
            }
        },

        moveToNextQuestion: (state, action) => {
            if (action?.payload < state.questionList.length) {
                viewSpecificQuestion(state, action)
            }
        },
        moveToPreviousQuestion: (state, action) => {
            if (action?.payload >= 0) {
                viewSpecificQuestion(state, action)
            }
        },

        setViewSpecificQuestion: (state, action) => {
            viewSpecificQuestion(state, action)
        },
        // 0: Not Visited, 1: Answered, 2:Marked 3:Marked and answered 4:Not Answered
        setMarkedQuestion: (state, action) => {
            const t = action.payload.currentQuestion.timeSpent + action.payload?.time;
            let qState;
            // 
            // const qState = action.payload.currentQuestion.state === 4 ? 2 : 3
            if (action.payload.currentQuestion.state == 4) {
                qState = 2;
            } else if (action.payload.currentQuestion.state == 1) {
                qState = 3;
            } else if (action.payload.currentQuestion.state == 2) {
                qState = 2
            } else if (action.payload.currentQuestion.state == 3) {
                qState = 3
            }
            // 

            state.questionList[action.payload.index] = { ...action.payload.currentQuestion, state: qState, timeSpent: t }
        },


        setEmptyPaperData: (state) => {
            state.paperData = state.paperData.length(0);
        },

        setemptyQuestionList: (state) => {
            state.questionList = state.questionList.length(0);
        }
        ,
        setDrawerOpen: (state) => {
            state.isDrawerOpen = !state.isDrawerOpen;
        },
        setInstructionVisible: (state, action) => {
            state.isInstructionVisible = action.payload;
        },
        setQuestionPaperVisible: (state, action) => {
            state.isQuestionPaperVisible = action.payload;
        },
        setResult: (state, action) => {
            state.result = action.payload;
        },
        setSectionIndex: (state, action) => {
            state.SectionIndex.SectionStartIndex = action.payload.startIndex;
            state.SectionIndex.SectionEndIndex = action.payload.endIndex;
            state.SectionIndex.StartSectionName = action.payload.StartSectionName;
            state.SectionIndex.EndSectionName = action.payload.EndSectionName;

        }
    }
})

export const {setExamPaperDataResponse
,
setClearResponse,
setMarkedQuestion,
setPaperData,
setQuestionAnswerSelection,
setMultiQuestionAnswerSelection,
setMultiNotAnswerSate,
setOrQuestionAnswerSelection,
setSectionIndex,
setPaperSection,
setEmptyPaperData,
setQuestionList,
setemptyQuestionList,
setDrawerOpen,
setInstructionVisible,
setQuestionPaperVisible,
setisAnwered,
setViewSpecificQuestion,
setisMarked,
setisMarkedandAnswred,
setisNotVisited,
removeisMarked,
moveToNextQuestion,
moveToPreviousQuestion,
setResult,
setQuestionTime
}=ExamFlowSlice.actions;
export default ExamFlowSlice.reducer;
