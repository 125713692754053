import { createSlice } from '@reduxjs/toolkit';
import {
  createBookAPI,
  deleteBookAPI,
  getBookListAPI,
  retrieveBookAPI,
  updateBookAPI,
} from 'src/service/BookService.js';

const initialState = {
  createModalOpen: false,
  createEditModal: false,
  allBooks: [],
  validationErrors: {},
};

export const BooksManager = createSlice({
  name: 'Book',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    openEditModal: (state) => {
      state.createEditModal = true;
    },
    closeEditModal: (state) => {
      state.createEditModal = false;
    },
    setAllBooks: (state, action) => {
      state.allBooks = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addBooks: (state, action) => {
      state.allBooks = [action.payload, ...state.allBooks];
    },
    updateBooks: (state, action) => {

      // Below code is to update the question in the array of questions
      const index = state.allBooks.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allBooks = [...state.allBooks.slice(0, index), action.payload?.data, ...state.allBooks.slice(index + 1)];
    },

    deleteBooks: (state, action) => {
      const index = action.payload;
      state.allBooks.splice(index, 1);
    },
  },
});

export const {
  openCreateModal,
  closeCreateModal,
  setAllBooks,
  setValidationErrors,
  clearValidationErrors,
  addBooks,
  updateBooks,
  deleteBooks,
  openEditModal,
  closeEditModal

} = BooksManager.actions;

export const getBookListAction = (param) => async (dispatch) => getBookListAPI(param);

export const retrieveBookAction = (id) => async (dispatch) => retrieveBookAPI(id);

export const createBookAction = (param) => async (dispatch) => createBookAPI(param);

export const updateBookAction = (param) => async (dispatch) => updateBookAPI(param);

export const deleteBookAction = (id) => async (dispatch) => deleteBookAPI(id);

export default BooksManager.reducer;
