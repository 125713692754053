import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

/**
 * The function is an asynchronous API call to retrieve a user list based on the given parameter.
 *
 * Args:
 *   param: The `param` parameter is an object that contains any additional parameters or filters that you want to pass to
 * the API endpoint.
 */
export const getUserListAPI = async (url) => {
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/user/`);
  return response;
};

export const getUserCashDetailsListAPI = async (url) => {
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/cash-details/`);
  return response;
};

export const getUserFeedbackDetailsListAPI = async (url) => {
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/system_feedback/`);
  return response;
};

export const getBasicUserListAPI = async (url) => {
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/${url}`);
  return response;
}

export const getUserInstituteFeatureAPI = async (url) => {
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/institutefeatures/`);
  return response;
};
//Role
export const getUserRoleAPI = async (url) => {
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/role/`);
  return response;
};
export const getUserPermissionAPI = async (url) => {
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/permissions/`);
  return response;
};
export const updateRoleAPI = async (param) => {
  const { id, values } = param;
  //
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/role/${id}/`,
    method: 'PATCH',
    headers: headersList,
    data: JSON.stringify({ ...values }),
  };

  return await axios.request(reqOptions);
};
export const createRoleAPI = async (param) => {
  //
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/role/`,
    method: 'POST',
    headers: headersList,
    data: JSON.stringify({ ...param }),
  };
  //

  const response = await axios.request(reqOptions);
  //
  return response;
};
/**
 * The function retrieves a user API based on the provided ID.
 */
export const retrieveUserAPI = async (id) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/user/${id}/`,
    method: 'GET',
    headers: headersList,
  };
  const response = await axios.request(reqOptions);
  return response;
};

/**
 * The function creates a user API using the provided parameter.
 *
 * Args:
 *   param: The `param` parameter is an object that contains the necessary information to create a user API.
 */
export const createUserAPI = async (param) => {
  //
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/user/`,
    method: 'POST',
    headers: headersList,
    data: JSON.stringify({ ...param }),
  };
  //

  const response = await axios.request(reqOptions);
  //
  return response;
};

/**
 * The function "updateUserAPI" is an asynchronous function that takes a parameter and performs some action.
 *
 * Args:
 *   param: The `param` parameter is an object that contains the necessary information to update a user API.
 */
export const updateUserAPI = async (param) => {
  const { id, values } = param;
  //
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/update-user/${id}/`,
    method: 'PATCH',
    headers: headersList,
    data: JSON.stringify({ ...values }),
  };

  return await axios.request(reqOptions);
};

export const updateAdminUserAPI = async (param) => {
  const { id, values } = param;
  //
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/user/${id}/`,
    method: 'PATCH',
    headers: headersList,
    data: JSON.stringify({ ...values }),
  };

  return await axios.request(reqOptions);
};
export const verifyPhoneNumberEmail = async (param) => {
  const { user_id, phone } = param;
  //
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/auth/otp/generate-otp-for-email-or-phone-number/`,
    method: 'POST',
    headers: headersList,
    data: JSON.stringify({ ...param }),
  };

  return await axios.request(reqOptions);
};

/**
 * The deleteUserAPI function is an asynchronous function that takes an object with an "id" property as a parameter and
 * deletes the corresponding data from the API.
 */
export const deleteUserAPI = async (param) => {
  const { id } = param;
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/user/${id}/`,
    method: 'DELETE',
    headers: headersList,
  };
  return await axios.request(reqOptions);
};
