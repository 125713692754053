import { createSlice } from '@reduxjs/toolkit';
import {
    createSubjectChapterAPI,
    updateSubjectChapterAPI,
    getSubjectChapterListAPI,
    retrieveSubjectChapterAPI,
    deleteSubjectChapterAPI,
} from 'src/service/SubjectChapterService.js';

const initialState = {
    createModalOpen: false,
    createEditModal: false,
    allSubjectChapters: [],
    validationErrors: {},
};

export const SubjectChapterManager = createSlice({
    name: 'subjectChapter',
    initialState,
    reducers: {
        openCreateModal: (state) => {
            state.createModalOpen = true;
        },
        closeCreateModal: (state) => {
            state.createModalOpen = false;
        },
        openEditModal: (state) => {
            state.createEditModal = true;
        },
        closeEditModal: (state) => {
            state.createEditModal = false;
        },
        setAllSubjectChapter: (state, action) => {

            state.allSubjectChapters = action.payload;
        },
        setValidationErrors: (state, action) => {
            state.validationErrors = action.payload;
        },
        clearValidationErrors: (state) => {
            state.validationErrors = {};
        },
        addSubjectChapter: (state, action) => {

            state.allSubjectChapters = [action.payload, ...state.allSubjectChapters];
        },
        updateSubjectChapter: (state, action) => {

            // Below code is to update the question in the array of questions
            const index = state.allSubjectChapters.findIndex((obj) => obj.id === action.payload?.data?.id);
            state.allSubjectChapters = [...state.allSubjectChapters.slice(0, index), action.payload?.data, ...state.allSubjectChapters.slice(index + 1)];
        },

        deleteSubjectChapter: (state, action) => {
            const index = action.payload;
            state.allSubjectChapters.splice(index, 1);
        },
    },
});

export const {
    openCreateModal,
    closeCreateModal,
    setAllSubjectChapter,
    setValidationErrors,
    clearValidationErrors,
    addSubjectChapter,
    updateSubjectChapter,
    deleteSubjectChapter,
    openEditModal,
    closeEditModal

} = SubjectChapterManager.actions;

export const getSubjectChapterListAction = (param) => async (dispatch) => getSubjectChapterListAPI(param);

export const retrieveSubjectChapterAction = (id) => async (dispatch) => retrieveSubjectChapterAPI(id);

export const createSubjectChapterAction = (param) => async (dispatch) => createSubjectChapterAPI(param);

export const updateSubjectChapterAction = (param) => async (dispatch) => updateSubjectChapterAPI(param);

export const deleteSubjectChapterAction = (id) => async (dispatch) => deleteSubjectChapterAPI(id);

export default SubjectChapterManager.reducer;
