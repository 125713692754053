import { createSlice } from '@reduxjs/toolkit';
import { calculateCoinRequiredAPI, createPaperGeneratorAPI, deletePaperGeneratorAPI, getAttemptedPaperListAPI, getPaperGeneratorListAPI, paperAttemptAPI, retrieveAnalysisGeneratorAPI, retrievePaperBasicInfoAPI, retrievePaperGeneratorAPI, retrievePaperInfoAPI, specialRequiredAPI, updatePaperGeneratorAPI } from 'src/service/PaperGeneratorService';

const initialState = {
    questionList: [],
    selectedQuestionList: [],
    paperList: [],
    filterList: [],
    questionsList: [],

    questionCount: 0,
    totalDuration: 0,
    totalMarks: 0,
    mode: "subjective",


    paperAttemptResponse: {},
    selectedStandardId: null,
    selectedSubjectId: null,
    workSheetType: "",
    workSheetVisibility: "",
    workSheetDifficulty: "",
    workSheetPricing: "",
    numberOfQuestions: "",
    numberOfMarks: "",
    selectedBoard: "",
    selectedTag: "",
    selectedNature: "",
    selectedBloom: "",

};

export const WorkSheetFilter = createSlice({
    name: 'worksheetfilter',
    initialState,
    reducers: {
        setSelectedQuestionList: (state, action) => {
            if (action.payload.type === "add") {
                state.selectedQuestionList = [...state.selectedQuestionList, ...action.payload.payload]
            } else if (action.payload.type === "remove") {
                state.selectedQuestionList = state.selectedQuestionList.filter((i) => i.id !== action.payload.payload?.id)
            } else if (action.payload.type === "replace") {
                state.selectedQuestionList = action.payload.payload
            }
        },
        setQuestionList: (state, action) => {
            if (action.payload.type === "add") {
                state.questionList = [...state.questionList, ...action.payload.payload]
            } else if (action.payload.type === "remove") {
                state.questionList = state.questionList.filter((i) => i.id !== action.payload.payload?.id)
            } else if (action.payload.type === "replace") {
                state.questionList = action.payload.payload
            }

        },

        setTotalDuration: (state, action) => {
            if (action.payload.type === "add") {
                state.totalDuration = state.totalDuration + Number(action.payload.payload) / 60
            } else if (action.payload.type === "sub") {
                if (state.totalDuration >= (Number(action.payload.payload) / 60)) {
                    state.totalDuration = state.totalDuration - Number(action.payload.payload) / 60
                }
            } else if (action.payload.type === "replace") {
                state.totalDuration = Number(action.payload.payload)
            }
        },

        setTotalMarks: (state, action) => {
            if (action.payload.type === "add") {
                state.totalMarks = state.totalMarks + Number(action.payload.payload)
            } else if (action.payload.type === "sub") {
                state.totalMarks = state.totalMarks - Number(action.payload.payload)
            } else if (action.payload.type === "replace") {
                state.totalMarks = Number(action.payload.payload)
            }
        },
        setPaperList: (state, action) => {
            state.paperList = action.payload;
        },
        storePaperAttemptResponse: (state, action) => {

            state.paperAttemptResponse = action.payload;
        },
        setFilterList: (state, action) => {

            state.filterList = action.payload;
        },
        setQuestionsList: (state, action) => {
            state.questionsList = action.payload;
        },
        setQuestionMarks: (state, action) => {
            const { marks, negative_marks } = action.payload.marksAndNegativeMarks;
            state.selectedQuestionList[action.payload.index] = { ...action.payload.question, marks: Number(marks), negative_marks: Number(negative_marks) }
            state.totalMarks = state.totalMarks - Number(action.payload.question.marks) + Number(marks)
        },
        setNegativeMarks: (state, action) => {
            const negative_marks = action.payload.NegativeMarks;
            state.selectedQuestionList[action.payload.index] = { ...action.payload.question, negative_marks: Number(negative_marks) }
        },
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        setQuestionCount: (state, action) => {
            state.questionCount = action.payload;
        },

        setSelectedStandardId: (state, action) => {
            state.selectedStandardId = action.payload;
        },
        setSelectedSubjectId: (state, action) => {
            state.selectedSubjectId = action.payload;
        },
        setWorkSheetType: (state, action) => {
            state.workSheetType = action.payload.value;
        },
        setWorkSheetVisibility: (state, action) => {
            state.workSheetVisibility = action.payload;
        },
        setWorkSheetDifficulty: (state, action) => {
            state.workSheetDifficulty = action.payload;
        },
        setWorkSheetPricing: (state, action) => {
            state.workSheetPricing = action.payload;
        },
        setNumberOfQuestions: (state, action) => {
            state.numberOfQuestions = action.payload;
        },
        setNumberOfMarks: (state, action) => {
            state.numberOfMarks = action.payload;
        },
        setSelectedBoard: (state, action) => {
            state.selectedBoard = action.payload;
        },
        setSelectedTag: (state, action) => {
            state.selectedTag = action.payload;
        },
        setSelectedBloom: (state, action) => {
            state.selectedBloom = action.payload;
        },
        setSelectedNature: (state, action) => {
            state.selectedNature = action.payload;
        },
        resetSelection: (state) => {
            state.selectedStandardId = null;
            state.selectedSubjectId = null;
        },
    },
});

export const {
    setMode,
    setQuestionMarks,
    setPaperList,
    setFilterList,
    setQuestionsList,
    setQuestionList,
    setQuestionCount,
    setTotalMarks,
    setTotalDuration,
    setSelectedQuestionList,
    setSelectedStandardId,
    setSelectedSubjectId,
    setWorkSheetType,
    setWorkSheetVisibility,
    setWorkSheetDifficulty,
    setWorkSheetPricing,
    setNumberOfMarks,
    setNumberOfQuestions,
    setNegativeMarks,
    setSelectedBoard,
    setSelectedTag,
    setSelectedBloom,
    setSelectedNature,
    resetSelection,
    storePaperAttemptResponse
} = WorkSheetFilter.actions;

export default WorkSheetFilter.reducer;

export const getAttemptedPaperListAction = (param) => async (dispatch) => getAttemptedPaperListAPI(param);
export const getPaperGeneratorListAction = (param) => async (dispatch) => getPaperGeneratorListAPI(param);

export const retrievePaperGeneratorAction = (id) => async (dispatch) => retrievePaperGeneratorAPI(id);

export const retrievePaperInfoAction = (id) => async (dispatch) => retrievePaperInfoAPI(id);

export const retrieveAnaGeneratorAction = (id) => async (dispatch) => retrieveAnalysisGeneratorAPI(id);

export const paperAttemptAction = (param) => async (dispatch) => paperAttemptAPI(param);

export const createPaperGeneratorAction = (param) => async (dispatch) => createPaperGeneratorAPI(param);

export const paperBasicInfoAction = (param) => async (dispatch) => retrievePaperBasicInfoAPI(param)

export const calculateCoinRequiredAction = (param) => async (dispatch) => calculateCoinRequiredAPI(param);

export const claculateSpecialRequiredAction = (param) => async (dispatch) => specialRequiredAPI(param);

export const updatePaperGeneratorAction = (param) => async (dispatch) => updatePaperGeneratorAPI(param);

export const deletePaperGeneratorAction = (id) => async (dispatch) => deletePaperGeneratorAPI(id);