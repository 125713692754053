import { createSlice } from '@reduxjs/toolkit';
import {
  createTopicAPI,
  deleteTopicAPI,
  getTopicListAPI,
  retrieveTopicAPI,
  updateTopicAPI,
} from 'src/service/TopicService.js';

const initialState = {
  createModalOpen: false,
  allTopics: [],
  validationErrors: {},
};

export const TopicManager = createSlice({
  name: 'Topic',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    setAllTopics: (state, action) => {
      state.allTopics = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addTopic: (state, action) => {
      state.allTopics = [action.payload, ...state.allTopics];
    },
    updateTopic: (state, action) => {
      // Below code is to update the question in the array of questions
      const index = state.allTopics.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allTopics = [...state.allTopics.slice(0, index), action.payload?.data, ...state.allTopics.slice(index + 1)];
    },

    deleteTopic: (state, action) => {
      const index = action.payload;
      state.allTopics.splice(index, 1);
    },
  },
});

export const {
  openCreateModal,
  closeCreateModal,
  setAllTopics,
  setValidationErrors,
  clearValidationErrors,
  addTopic,
  updateTopic,
  deleteTopic,
} = TopicManager.actions;

export const getTopicListAction = (param) => async (dispatch) => getTopicListAPI(param);

export const retrieveTopicAction = (id) => async (dispatch) => retrieveTopicAPI(id);

export const createTopicAction = (param) => async (dispatch) => createTopicAPI(param);

export const updateTopicAction = (param) => async (dispatch) => updateTopicAPI(param);

export const deleteTopicAction = (id) => async (dispatch) => deleteTopicAPI(id);

export default TopicManager.reducer;
