import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeDir: 'ltr',
  activeMode: 'light', // This can be light or dark
  activeTheme: 'PURPLE_THEME', // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME, ORANGE_THEME, CYAN_THEME
  SidebarWidth: 250,
  MiniSidebarWidth: 87,
  TopbarHeight: 70,
  isLayout: 'boxed', // This can be full or boxed
  isCollapse: false, // to make sidebar Mini by default
  isSidebarHover: false,
  isMobileSidebar: false,
  isHorizontal: false,
  isLanguage: 'en',
  isCardShadow: true,
  borderRadius: 15,
  isFullScreen: false,
  showDrawer: false
};

export const CustomizerSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {
    setAllTheme: (state, action) => {
      state.activeTheme = action.payload.activeTheme;
      state.activeMode = action.payload.activeMode;
      state.activeDir = action.payload.activeDir;
      state.isLanguage = action.payload.isLanguage;
      state.isCardShadow = action.payload.isCardShadow;
      state.isLayout = action.payload.isLayout;
      state.isHorizontal = action.payload.isHorizontal;
      // state.borderRadius = action.payload.borderRadius;
    },
    setTheme: (state, action) => {
      state.activeTheme = action.payload;
    },
    setDarkMode: (state, action) => {
      state.activeMode = action.payload;
    },
    setDir: (state, action) => {
      state.activeDir = action.payload;
    },
    setLanguage: (state, action) => {
      state.isLanguage = action.payload;
    },
    setCardShadow: (state, action) => {
      state.isCardShadow = action.payload;
    },
    toggleSidebar: (state) => {
      state.isCollapse = !state.isCollapse;
    },
    hoverSidebar: (state, action) => {
      state.isSidebarHover = action.payload;
    },
    toggleMobileSidebar: (state) => {
      state.isMobileSidebar = !state.isMobileSidebar;
    },
    toggleLayout: (state, action) => {
      state.isLayout = action.payload;
    },
    toggleHorizontal: (state, action) => {
      state.isHorizontal = action.payload;
    },
    setBorderRadius: (state, action) => {
      state.borderRadius = action.payload;
    },
    toggleFullScreen: (state) => {
      state.isFullScreen = !state.isFullScreen;
    },
    fullScreen: (state, payload) => {
      state.isFullScreen = payload;
    },
    toggleSystemSettingDrawer: (state, action) => {
      state.showDrawer = action.payload;
    },
  },
});

export const {
  setAllTheme,
  setTheme,
  setDarkMode,
  setDir,
  toggleSidebar,
  hoverSidebar,
  toggleMobileSidebar,
  toggleLayout,
  setBorderRadius,
  toggleHorizontal,
  setLanguage,
  setCardShadow,
  toggleFullScreen,
  fullScreen,
  toggleSystemSettingDrawer,
} = CustomizerSlice.actions;

export default CustomizerSlice.reducer;
