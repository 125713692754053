import { createSlice } from '@reduxjs/toolkit';
import {
  createAnswerAPI,
  deleteAnswerAPI,
  getAnswerListAPI,
  retrieveAnswerAPI,
  updateAnswerAPI,
} from 'src/service/AnswerService.js';

const initialState = {
  createModalOpen: false,
  allAnswers: [],
  validationErrors: {},
};

export const AnswerManager = createSlice({
  name: 'Answer',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    setAllAnswers: (state, action) => {
      state.allAnswers = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addAnswer: (state, action) => {
      state.allAnswers = [action.payload, ...state.allAnswers];
    },
    updateAnswer: (state, action) => {
      const { index, values } = action.payload;
      state.allAnswers[index] = { ...state.allAnswers[index], ...values };
    },
    deleteAnswer: (state, action) => {
      const index = action.payload;
      state.allAnswers.splice(index, 1);
    },
  },
});

export const {
  openCreateModal,
  closeCreateModal,
  setAllAnswers,
  setValidationErrors,
  clearValidationErrors,
  addAnswer,
  updateAnswer,
  deleteAnswer,
} = AnswerManager.actions;

export const getAnswerListAction = (param) => async (dispatch) => getAnswerListAPI(param);

export const retrieveAnswerAction = (id) => async (dispatch) => retrieveAnswerAPI(id);

export const createAnswerAction = (param) => async (dispatch) => createAnswerAPI(param);

export const updateAnswerAction = (param) => async (dispatch) => updateAnswerAPI(param);

export const deleteAnswerAction = (id) => async (dispatch) => deleteAnswerAPI(id);

export default AnswerManager.reducer;
