import { createSlice } from '@reduxjs/toolkit';
import { ChartAnalysisPaperGeneratorAPI, LeaderBoardPaperGeneratorAPI, PaperAnalysisPaperGeneratorAPI } from 'src/service/PaperAnalysisService';


const initialState = {
    paperId: "",
    leaderBoardData: [],
    paperAnswerData: [],
    scoreData: {},
};

const PaperAnalysisReducer = createSlice({

    name: "paperAnalysis",
    initialState: initialState,
    reducers: {
        setPaperId: (state, action) => {
            state.paperId = action.payload;
        },
        setLeaderBoardData: (state, action) => {
            state.leaderBoardData = action.payload;
        },
        setScoreData: (state, action) => {
            state.scoreData = action.payload;
        },
        setPaperAnswerData: (state, action) => {
            state.paperAnswerData = action.payload;
        }

    }

});

export const {
    setPaperId,
    setLeaderBoardData,
    setPaperAnswerData,
    setScoreData,

} = PaperAnalysisReducer.actions;


export const getLeaderBoardGeneratorListAction = (param) => async (dispatch) => LeaderBoardPaperGeneratorAPI(param);

export const getPaperAnalysisGeneratorListAction = (param) => async (dispatch) => PaperAnalysisPaperGeneratorAPI(param);

export const getChartAnalysisGeneratorListAction = (param) => async (dispatch) => ChartAnalysisPaperGeneratorAPI(param);

export default PaperAnalysisReducer.reducer;


