import axios from 'axios';
import MuiPhoneNumber from 'material-ui-phone-number';
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getBasicUserListAction, setAllUsers, userEmailPhoneVerify } from "src/store/UserManagerReducer";
import * as yup from 'yup';
// import useAuth from 'src/guards/authGuard/UseAuth';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Autocomplete,
    Avatar,
    Button,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputBase,
    MenuItem,
    Select,
    Stack,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { IconEdit } from '@tabler/icons';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import user_student from 'src/assets/images/user-1.jpg';
import ImageUpload from 'src/components/Image/ImageUpload';
import AddressDialog from 'src/components/dialog/AddressDialog';
import SchoolAddressDialog from 'src/components/dialog/SchoolAddressDialog';
import CustomTextField from 'src/components/forms/theme-elements/CustomTextField';
import BlankCard from 'src/components/shared/BlankCard';
import { ErrorNotification } from 'src/cutomHooks/userTableTheme';
import useAuth from 'src/guards/authGuard/UseAuth';
import { getStandardListAction } from 'src/store/QuestionManager/StandardReducer';
import { getSubjectListAction } from 'src/store/QuestionManager/SubjectReducer';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
// import useAuth from 'src/guards/authGuard/UseAuth';

const ProfileUpdateTab = ({ handleClose }) => {
    const { updateInstituteLogo, instituteLogo, avatar, user, updateUserProfile } = useAuth();
    // const isFirstIn = localStorage.getItem('isFirstIn');
    const subjectStore = useSelector((state) => state.Subject);
    const standardStore = useSelector((state) => state.Standard);
    const userStore = useSelector((state) => state.UserManager);


    // Dispatch actions

    const dispatch = useDispatch();
    const authStore = useSelector((state) => state.Auth);
    const user_id = user?.id;
    const userData = user;

    const handleCancel = () => {
        handleClose();
    };

    //const [selectedAvatar, setSelectedAvatar] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [filter, setFilter] = useState([]);
    const [referalOpen, setReferalOpen] = useState(false);

    const [ConfirmationOpen, setConfirmationOpen] = useState(false);
    const [avatars, setAvatars] = useState([]);
    // const [referal, setReferal] = useState(userData?.referral_id || '');
    const [address, setAddress] = useState({
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
    });

    const [schoolAddress, setSchoolAddress] = useState({
        school_address_line_1: '',
        school_address_line_2: '',
        school_city: '',
        school_state: '',
        school_country: '',
        school_postal_code: '',
    });

    const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false);
    const [isSchoolAddressDialogOpen, setIsSchoolAddressDialogOpen] = useState(false);

    const handleOpenAddressDialog = () => {
        setIsAddressDialogOpen(true);
    };

    const handleCloseAddressDialog = () => {
        setIsAddressDialogOpen(false);
    };

    const handleOpenSchoolAddressDialog = () => {
        setIsSchoolAddressDialogOpen(true);
    };

    const handleCloseSchoolAddressDialog = () => {
        setIsSchoolAddressDialogOpen(false);
    };

    useEffect(() => {

        if (userData?.user_type !== undefined && userData?.user_type !== null) {
            const handelAPICall = debounce(() => {
                const url = new URL('/basic-user-info/?user_type=0', REACT_APP_BACKEND_URL);
                url.searchParams.set('filters', JSON.stringify(filter ?? []));
                url.searchParams.set('limit', 10);

                dispatch(getBasicUserListAction(url))
                    .then((response) => {
                        dispatch(setAllUsers(response.data?.data?.results));
                    })
                    .catch((error) => {
                        ErrorNotification(error.response);
                    });
            }, 500);
            handelAPICall();
            return () => {
                handelAPICall.cancel();
            };
        }
    }, [filter]);

    const [standard, setStandard] = useState(standardStore?.allStandards);
    const [standardFilter, setStandardFilter] = useState([]);
    useEffect(() => {
        const handelAPICall = debounce(() => {
            const url = new URL('/standard/', REACT_APP_BACKEND_URL);
            url.searchParams.set('filters', JSON.stringify(standardFilter ?? []));
            dispatch(getStandardListAction(url))
                .then((response) => {
                    setStandard(response.data?.data?.results);
                })
                .catch((error) => {

                    ErrorNotification(error.response)
                });
        }, 500);
        handelAPICall();
        return () => {
            handelAPICall.cancel();
        };
    }, [standardFilter]);

    const [subject, setSubject] = useState(subjectStore?.allSubjects);
    const [subjectFilter, setSubjectFilter] = useState([]);
    useEffect(() => {
        const handelAPICall = debounce(() => {
            const url = new URL('/subject/', REACT_APP_BACKEND_URL);
            url.searchParams.set('filters', JSON.stringify(subjectFilter ?? []));
            dispatch(getSubjectListAction(url))
                .then((response) => {
                    setSubject(response.data?.data?.results);
                })
                .catch((error) => {
                    ErrorNotification(error.response)
                });
        }, 500);
        handelAPICall();
        return () => {
            handelAPICall.cancel();
        };
    }, [subjectFilter]);

    //OTP Modal Box For the Verify OTP

    const [otp, setOtp] = useState('');
    const [otpModalOpen, setOtpModalOpen] = useState(false);
    const [is_phone_number_verified, set_Is_phone_number_verified] = useState(false);
    const [is_email_verified, set_is_email_verified] = useState(false);
    const [isEmailChange, setIsEmailChange] = useState(false)



    // Function to open OTP modal
    const handleOtpModalOpen = () => {
        setOtpModalOpen(true);
    };

    // Function to close OTP modal
    const handleOtpModalClose = () => {
        setOtpModalOpen(false);
    };

    const handleOtpSubmit = () => {
        const otpValue = otp; // Get the OTP from the state
        // Create the payload
        const payload = {
            otp: otpValue,
            otp_user_id: user_id, // Assuming user_id is defined
            phone_number_or_email: isEmailChange ? formik.values.email : formik.values.phone_number, // Use the appropriate field from your form data
        };

        // Make a POST request to verify the OTP
        axios.post(`${REACT_APP_BACKEND_URL}/auth/otp/verify-otp-for-email-or-phone-number/`, payload)
            .then((response) => {
                set_Is_phone_number_verified(true)
                handleOtpModalClose();
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.data.message, { variant: 'error' })
                // handleOtpModalClose();
            });
    };

    const handleAddressSubmit = () => {
        formik.setFieldValue('address', `${formik.values.address_line_1} ${formik.values.address_line_2} ${formik.values.city} ${formik.values.state} ${formik.values.country} ${formik.values.postal_code}`)
        handleCloseAddressDialog();
    };

    const handleSchoolAddressSubmit = () => {
        formik.setFieldValue('school_address', `${formik.values.school_address_line_1} ${formik.values.school_address_line_2} ${formik.values.school_city} ${formik.values.school_state} ${formik.values.school_country} ${formik.values.school_postal_code}`);
        handleCloseSchoolAddressDialog();
    };

    // const handleRefferalClose = () => {
    //     setReferalOpen(false)
    //     localStorage.removeItem('isFirstIn');
    // }

    useEffect(() => {
        // Generate avatars with different seed values
        const generateAvatars = async () => {
            const avatarSeeds = Array.from({ length: 15 }, (_, index) => index * 7);
            const avatarURLs = await Promise.all(
                avatarSeeds.map(async (seed) => {
                    const avatarURL = `https://api.dicebear.com/6.x/identicon/svg?seed=${seed}`;
                    return avatarURL;
                })
            );
            setAvatars(avatarURLs);
        };

        generateAvatars();
    }, []);

    const verifyPhoneNumberEmail = async (phone) => {
        enqueueSnackbar('Request Sending...', { variant: 'info' });
        try {
            const response = await dispatch(userEmailPhoneVerify({ otp_user_id: user_id, phone_number_or_email: phone }));
            var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (phone.match(emailRegex)) {
                setIsEmailChange(true)
            }
            // Handle success, e.g., show a success message to the user
            enqueueSnackbar('OTP sent Successful!', { variant: 'success' });

            // Open the OTP modal
            handleOtpModalOpen();
        } catch (error) {
            const { data } = error.response?.data;
            const { message = "Something went wrong." } = data;
            enqueueSnackbar(message, { variant: 'error' });
        }
    };


    const validationSchema = yup.object({
        first_name: yup.string().required('First name is required.'),
        // phone_number: yup
        //     .string()
        //     .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
    });

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            gender: 0,
            address: "",
            school_name: '',
            school_address: "",
            subject: [],
            standard: [],
            mentor: {},
            address_line_1: "",
            address_line_2: "",
            city: "",
            state: "",
            country: "",
            postal_code: "",
            school_address_line_1: "",
            school_address_line_2: "",
            school_city: "",
            school_state: "",
            school_country: "",
            school_postal_code: "",
        },
        validationSchema: validationSchema,

        onSubmit: (values) => {
            // const subjectIds = Array.isArray(values.subject) ? values.subject.map((subject) => subject.id) : [];
            const standardIds = Array.isArray(values.standard) ? values.standard.map((standard) => standard.id) : [];
            const addressExists = values.address_line_1 || values.address_line_2 || values.city || values.state || values.country || values.postal_code;
            const schoolAddressExists = values.school_address_line_1 || values.school_address_line_2 || values.school_city || values.school_state || values.school_country || values.school_postal_code;
            const data = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: userData.is_email_verified ? values.email : '',
                phone_number: `${values.phone_number.replace('-', '')}`,
                gender: values.gender,
                ...(addressExists && {
                    address: {
                        address_line_1: values.address_line_1 || '',
                        address_line_2: values.address_line_2 || '',
                        city: values.city || '',
                        state: values.state || '',
                        country: values.country || '',
                        postal_code: values.postal_code || ''
                    }
                }),
                school_name: values.school_name,
                ...(schoolAddressExists && {
                    school_address: {
                        address_line_1: values.school_address_line_1 || '',
                        address_line_2: values.school_address_line_2 || '',
                        city: values.school_city || '',
                        state: values.school_state || '',
                        country: values.school_country || '',
                        postal_code: values.school_postal_code || ''
                    }
                }),
                // subjects: subjectIds,
                standards: standardIds,
                mentor: values?.mentor?.id,
            }
            updateUserProfile({ id: user_id, values: data })
            handleClose()
        },
    });

    useEffect(() => {
        const addressParts = (userData?.profile?.address || "").split("|").map(part => part.trim());
        const selectedStandardIds = userData?.profile?.standards || [];
        const selectedStandards = standard.filter(standard => selectedStandardIds.includes(standard.id));


        formik.setFieldValue('standard', selectedStandards);

        const selectedSubjectIds = userData?.profile?.subjects || [];
        const selectedSubjects = subject.filter(subject => selectedSubjectIds.includes(subject.id));

        formik.setFieldValue('subject', selectedSubjects);


        const schoolAddressParts = (userData?.profile?.school_address || "").split("|").map(part => part.trim());

        formik.setValues({
            ...formik.values,
            first_name: userData?.first_name || '',
            last_name: userData?.last_name || '',
            email: userData?.email || '',
            phone_number: (userData?.phone_number || ''),
            gender: userData?.profile?.gender || 0,
            school_name: userData?.profile?.school_name,
            subject: userData?.profile?.subjects,
            standard: userData?.profile?.standards || [],
            mentor: userData?.profile?.mentor || {},
            address: userData?.profile?.address?.replace(/\|/g, ' ') || "",
            address_line_1: addressParts[0] || "",
            address_line_2: addressParts[1] || "",
            city: addressParts[2] || "",
            state: addressParts[3] || "",
            country: addressParts[4] || "",
            postal_code: addressParts[5] || "",
            school_address: userData?.profile?.school_address?.replace(/\|/g, ' ') || "",
            school_address_line_1: schoolAddressParts[0] || "",
            school_address_line_2: schoolAddressParts[1] || "",
            school_city: schoolAddressParts[2] || "",
            school_state: schoolAddressParts[3] || "",
            school_country: schoolAddressParts[4] || "",
            school_postal_code: schoolAddressParts[5] || ""
        });
        {
            userData?.is_phone_number_verified ?
                set_Is_phone_number_verified(true) : set_Is_phone_number_verified(false);
        }
        // setAddress({
        //     ...userData?.profile?.address
        // })
        // setSchoolAddress({ ...userData?.profile?.school_address })
        userData?.is_email_verified ? set_is_email_verified(true) : set_is_email_verified(false);


    }, [userData]);

    return (
        <>
            <BlankCard>
                <CardContent>
                    <Grid container justifyContent="space-between" alignItems="center" marginBottom={2}>
                        <Grid item >
                            <Typography variant="h4" mb={2}>
                                Update Profile
                            </Typography>
                        </Grid>

                        <Grid item >
                            <Avatar
                                sx={{ width: 60, height: 60, cursor: 'pointer' }}
                                src={avatar}
                                onClick={() => setIsDialogOpen(true)}
                            />
                        </Grid>
                    </Grid>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container flexDirection="column" marginBottom={0} paddingBottom={0} >
                            <Grid item marginBottom={0} paddingBottom={0}>
                                <Grid container flexDirection="row" >
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle1" fontSize="16px" color="textSecondary" >
                                            First name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} >
                                        <InputBase
                                            id="fs-fname"
                                            placeholder="Edit first name"
                                            name="first_name"
                                            value={formik.values?.first_name}
                                            onChange={formik.handleChange}
                                            inputProps={{ 'aria-label': 'Enter first name' }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container flexDirection="row" alignItems={"center"}>
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle1" fontSize="16px" color="textSecondary" >
                                            Last name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {/* <Grid container flexDirection="row" alignItems={"center"} justifyContent="space-between" > */}
                                        <InputBase
                                            id="fs-lname"
                                            placeholder="Edit last name"
                                            inputProps={{ 'aria-label': 'Edit last name' }}
                                            name="last_name"
                                            value={formik.values?.last_name}
                                            onChange={formik.handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item marginBottom={3}>
                                <Grid container flexDirection="row" alignItems={"center"}>
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle1" fontSize="16px" color="textSecondary" >
                                            Email
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} >
                                        <InputBase
                                            endAdornment={
                                                (is_email_verified && formik?.values?.email) ? (
                                                    <InputAdornment position="end">
                                                        <CheckCircleIcon style={{ color: 'green' }} />
                                                    </InputAdornment>
                                                ) : (
                                                    <InputAdornment position="end">
                                                        <Button variant="contained" size='small' color="primary" onClick={() => {
                                                            if (formik.values.phone_number.length < 0) {
                                                                enqueueSnackbar('Please enter a valid email id.', { variant: 'error' });
                                                            } else {
                                                                verifyPhoneNumberEmail(`${formik.values.email}`); // Send OTP
                                                                handleOtpModalOpen(); // Open the OTP modal
                                                            }
                                                        }}>
                                                            Verify OTP
                                                        </Button>
                                                        {/* OTP Modal */}
                                                        <Dialog open={otpModalOpen} onClose={handleOtpModalClose}>
                                                            <DialogTitle>Enter OTP</DialogTitle>
                                                            <DialogContent>
                                                                <CustomTextField
                                                                    label="OTP"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={otp}
                                                                    onChange={(e) => setOtp(e.target.value)}
                                                                />
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={handleOtpModalClose} color="primary">
                                                                    Cancel
                                                                </Button>
                                                                <Button onClick={handleOtpSubmit} color="primary">
                                                                    Submit OTP
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </InputAdornment>
                                                )
                                            }
                                            id="email"
                                            placeholder="example@gmail.com"
                                            fullWidth
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            disabled={is_email_verified}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container flexDirection="row" alignItems={"center"} gap={0}>
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle1" fontSize="16px" color="textSecondary">
                                            Phone number
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} >
                                        <MuiPhoneNumber
                                            defaultCountry={'in'}
                                            disableAreaCodes={true}
                                            countryCodeEditable={false}
                                            disableDropdown={is_phone_number_verified ? true : false}
                                            // autoFormat={false}
                                            id="phone_number"
                                            placeholder="0000000000"
                                            fullWidth
                                            name="phone_number"
                                            value={formik.values?.phone_number}
                                            onChange={(value) => {
                                                // Update Formik's state with the new phone number value
                                                formik.setFieldValue('phone_number', value);
                                            }}
                                            // maxLength={10}
                                            disabled={is_phone_number_verified}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    borderBottom: 'none', // Remove the underline
                                                },
                                                '& .MuiInput-underline:root': {
                                                    borderBottom: 'none', // Remove the underline
                                                },
                                                '& .MuiInput-underline:before': {
                                                    borderBottom: 'none', // Remove the underline
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottom: 'none', // Prevent underline after focus
                                                },
                                            }}
                                        />

                                    </Grid>
                                    <Grid item xs={2} display={"flex"} justifyContent={"end"} alignItems={"flex-end"}>
                                        {is_phone_number_verified ? (
                                            <CheckCircleIcon height={'25px'} style={{ color: 'green' }} />
                                        ) : (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        // if (!formik.values.phone_number || formik.values.phone_number.length !== 10) {
                                                        //     enqueueSnackbar('Please enter a valid 10 digit mobile number.', { variant: 'error' });
                                                        // } else {
                                                        verifyPhoneNumberEmail(`${formik.values.phone_number?.replace('-', '')}`); // Send OTP
                                                        handleOtpModalOpen(); // Open the OTP modal
                                                        // }
                                                    }}
                                                >
                                                    Verify OTP
                                                </Button>

                                                {/* OTP Modal */}
                                                <Dialog open={otpModalOpen} onClose={handleOtpModalClose}>
                                                    <DialogTitle>Enter OTP</DialogTitle>
                                                    <DialogContent>
                                                        <CustomTextField
                                                            label="OTP"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={otp}
                                                            onChange={(e) => setOtp(e.target.value)}
                                                            sx={{ marginTop: "10px" }}
                                                        />
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleOtpModalClose} color="primary">
                                                            Cancel
                                                        </Button>
                                                        <Button onClick={handleOtpSubmit} color="primary">
                                                            Submit OTP
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item marginBottom={3}>
                                <Grid container flexDirection="row" alignItems={"center"}>
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle1" fontSize="16px" color="textSecondary" >
                                            Gender
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <FormControl fullWidth size='small'>
                                            <Select
                                                placeholder="Gender"
                                                id="gender"
                                                name="gender"
                                                value={formik.values.gender}
                                                onChange={formik.handleChange}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value={0}>Male</MenuItem>
                                                <MenuItem value={1}>Female</MenuItem>
                                                <MenuItem value={3}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item marginBottom={3}>
                                <Grid container flexDirection="row" >
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle1" fontSize="16px" color="textSecondary" >
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} >
                                        <Grid container justifyContent={"space-between"}>
                                            <Grid item flex={1}>
                                                <InputBase
                                                    id="address"
                                                    placeholder="Edit your address"
                                                    name="address"
                                                    value={formik.values?.address}
                                                    inputProps={{ 'aria-label': 'Edit your address' }}
                                                    fullWidth
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <IconButton type="button" aria-label="search" onClick={handleOpenAddressDialog}>
                                                    <IconEdit size={"15px"} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container flexDirection="row" alignItems={"center"}>
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle1" fontSize="16px" color="textSecondary" >
                                            School name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {/* <Grid container flexDirection="row" alignItems={"center"} justifyContent="space-between" > */}
                                        <InputBase
                                            id="school_name"
                                            placeholder="Edit your school name"
                                            inputProps={{ 'aria-label': 'Edit your school name' }}
                                            name="school_name"
                                            value={formik.values?.school_name}
                                            onChange={formik.handleChange}
                                            fullWidth
                                        // disabled={true}
                                        />
                                        {/* <IconButton type="button" aria-label="search">
                                                <IconEdit size={"15px"} />
                                            </IconButton> */}
                                        {/*   </Grid> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item >
                                <Grid container flexDirection="row" >
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle1" fontSize="16px" color="textSecondary" >
                                            School Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} >
                                        <Grid container justifyContent={"space-between"}>
                                            <Grid item flex={1}>
                                                <InputBase
                                                    id="school_address"
                                                    placeholder="Edit your school address"
                                                    name="school_address"
                                                    value={formik.values.school_address}
                                                    fullWidth
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <IconButton type="button" aria-label="search" onClick={handleOpenSchoolAddressDialog}>
                                                    <IconEdit size={"15px"} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {userData?.user_type === 1 &&
                                <>
                                    <Grid item marginBottom={3}>
                                        <Grid container flexDirection="row" alignItems={"center"}>
                                            <Grid item xs={3}>
                                                <Typography variant="subtitle1" fontSize="16px" color="textSecondary" >
                                                    Standard
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                {/* <Grid container flexDirection="row" alignItems={"center"} justifyContent="space-between" > */}
                                                <Autocomplete
                                                    id="standard"
                                                    name='standard'
                                                    size="small"
                                                    // loading={props.isAutoCompleatLoading}
                                                    options={standard || []}
                                                    value={formik.values?.standard || []}
                                                    onChange={(event, newValue) => {
                                                        formik.setFieldValue('standard', newValue);
                                                        if (Array.isArray(newValue) && newValue.length === 0) {
                                                            formik.setFieldValue('subject', []);
                                                        } else {
                                                            setSubjectFilter([{ "id": "standard", "value": newValue.map((item) => item.id) }])
                                                        }
                                                    }}
                                                    onKeyUp={(event) => {
                                                        setStandardFilter([{ "id": "name", "value": event.target?.value }])
                                                    }}
                                                    getOptionLabel={(option) => `${option?.name} ` || ""}
                                                    multiple // Enable multiple selections
                                                    renderInput={(params) => <CustomTextField
                                                        variant="outlined"
                                                        {...params}
                                                        placeholder="Select Standard"
                                                    />}
                                                    renderOption={(props, option, { selected }) => {
                                                        return (
                                                            <MenuItem
                                                                {...props}
                                                                key={option.id}
                                                                value={option}
                                                                sx={{ justifyContent: "space-between", whiteSpace: "normal", wordWrap: "break-word" }}
                                                            >
                                                                {`${option?.name} `}
                                                            </MenuItem>
                                                        )
                                                    }}
                                                />

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item marginBottom={3}>
                                        <Grid container flexDirection="row" alignItems={"center"}>
                                            <Grid item xs={3}>
                                                <Typography variant="subtitle1" fontSize="16px" color="textSecondary">
                                                    Subject
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Autocomplete
                                                    id="subject"
                                                    name='subject'
                                                    size="small"
                                                    value={formik.values?.subject || []} // Provide an empty array as the initial value
                                                    onChange={(event, newValue) => {
                                                        formik.setFieldValue('subject', newValue);
                                                    }}
                                                    onKeyUp={(event) => {
                                                        formik.values.standard.length == 0
                                                            ? setSubjectFilter([{ "id": "name", "value": event.target.value }])
                                                            : setSubjectFilter([{ "id": "name", "value": event.target.value }, { "id": "standard", "value": formik.values.standard?.map(item => item.id) }])
                                                    }}
                                                    options={subject || []}
                                                    multiple // Enable multiple selections
                                                    getOptionLabel={(option) => `${option?.name} | ${option?.standard?.name} `}
                                                    renderInput={(params) => <CustomTextField {...params}
                                                        variant="outlined"
                                                        placeholder="Select Subject"
                                                    />}
                                                    renderOption={(props, option, { selected }) => {
                                                        return (
                                                            <MenuItem
                                                                {...props}
                                                                key={option?.id}
                                                                value={option}
                                                                sx={{ justifyContent: "space-between", whiteSpace: "normal", wordWrap: "break-word" }}
                                                            >
                                                                {`${option?.name} | ${option?.standard?.name || ''} `}
                                                            </MenuItem>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                    <Grid item marginBottom={2}>
                                        <Grid container flexDirection="row" alignItems={"center"}>
                                            <Grid item xs={3}>
                                                <Typography variant="subtitle1" fontSize="16px" color="textSecondary" >
                                                    Mentor
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={9}>
                                                {/* <Grid container flexDirection="row" alignItems={"center"} justifyContent="space-between" > */}
                                                <Autocomplete
                                                    id="mentor"
                                                    name="mentor"
                                                    size="small"
                                                    fullWidth
                                                    value={formik.values?.mentor}
                                                    onChange={(event, newValue) => {
                                                        formik.setFieldValue('mentor', newValue);
                                                    }}
                                                    error={formik.touched.user && Boolean(formik.errors.user)}
                                                    helperText={formik.touched.user && formik.errors.user}
                                                    options={(userStore.allUsers || [])}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => `${option?.email || ""}`}
                                                    onKeyUp={(event) => {
                                                        setFilter([{ "id": "email", "value": event.target.value }])
                                                    }}
                                                    renderInput={(params) => (
                                                        <CustomTextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Enter or select mentor"
                                                        />
                                                    )}
                                                    renderOption={(props, option) => (
                                                        <MenuItem
                                                            {...props}
                                                            key={option?.id}
                                                            value={option}
                                                            sx={{ justifyContent: "space-between", whiteSpace: "normal", wordWrap: "break-word" }}
                                                        >
                                                            {<Stack direction="row" spacing={2}>
                                                                <Avatar width="16" src={option?.avatar || user_student} />
                                                                <Box>
                                                                    <Typography variant="subtitle2" color={"primary"}>
                                                                        {`${option?.first_name} ${option?.last_name} (${option?.username})` || ''}
                                                                    </Typography>
                                                                    <Typography color="textSecondary" variant="body" fontSize={13}>
                                                                        {option?.email}
                                                                    </Typography>
                                                                </Box>
                                                            </Stack>}
                                                        </MenuItem>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>

                        <Grid item xs={12} display="flex" justifyContent="end">
                            <Stack direction="row" spacing={2}>
                                <Button type="submit" variant="contained" color="primary" size='medium'>
                                    Submit
                                </Button>
                            </Stack>
                        </Grid>
                    </form >
                </CardContent>

            </BlankCard >
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}
            >
                <Grid container display="flex" alignItems="center">
                    <Grid item xs={11} mb={2}>
                        <DialogTitle>Choose Image</DialogTitle>
                    </Grid>
                </Grid>
                <DialogContent>
                    <ImageUpload
                        setIsDialogOpen={setIsDialogOpen}
                    />
                </DialogContent>
            </Dialog>

            {/* Address Dialog */}
            <AddressDialog
                isOpen={isAddressDialogOpen}
                formik={formik}
                onClose={handleCloseAddressDialog}
                onSubmit={handleAddressSubmit}
                address={address}
                setAddress={setAddress}
            />

            {/* School Address Dialog */}
            <SchoolAddressDialog
                isOpen={isSchoolAddressDialogOpen}
                formik={formik}
                onClose={handleCloseSchoolAddressDialog}
                onSubmit={handleSchoolAddressSubmit}
                schoolAddress={schoolAddress}
                setSchoolAddress={setSchoolAddress}
            />

            {/* <RefferalDialog
                open={referalOpen}
                onClose={handleRefferalClose}
            /> */}
        </>
    )
}

export default ProfileUpdateTab;