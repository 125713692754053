
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };


/**
 * The function retrieves a PaperGenerator API based on the provided ID.
 */

export const LeaderBoardPaperGeneratorAPI = async (url) => {
    const response = await axios.get(url);
    return response;
};

export const PaperAnalysisPaperGeneratorAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/paper-attempted-question-wise-result/?id=${id}`,
        method: 'GET',
        headers: headersList,
    };
    const response = await axios.request(reqOptions);
    return response;
};

export const ChartAnalysisPaperGeneratorAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/paper-attempted-scores?id=${id}`,
        method: 'GET',
        headers: headersList,
    };
    const response = await axios.request(reqOptions);
    return response;
};