import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import ProfileFormPopup from 'src/components/components/ProfileFormPop';

import Loadable from '../layouts/full/shared/loadable/Loadable';
import {
  AccountSetting,
  AreaChart,
  BasicTable,
  BlankLayout,
  Blog,
  BlogDetail,
  Calendar,
  CandlestickChart,
  Chats,
  CollapsibleTable,
  ColumnChart,
  Contacts,
  DoughnutChart,
  Ecommerce,
  EcommerceDash,
  EcommerceDetail,
  EcomProductCheckout,
  EcomProductList,
  Email,
  EnhancedTable,
  Error,
  FixedHeaderTable,
  Followers,
  FormCustom,
  FormHorizontal,
  FormLayouts,
  FormValidation,
  FormVertical,
  FormWizard,
  Friends,
  FullLayout,
  Gallery,
  GredientChart,
  LineChart,
  Maintenance,
  ModernDash,
  MuiAccordion,
  MuiAlert,
  MuiAutoComplete,
  MuiAvatar,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiDateTime,
  MuiDialog,
  MuiList,
  MuiPopover,
  MuiRadio,
  MuiRating,
  MuiSlider,
  MuiSwitch,
  MuiTabs,
  MuiTooltip,
  MuiTransferList,
  MuiTypography,
  Notes,
  PaginationTable,
  // QuillEditor,
  RadialbarChart,
  RollbaseCASL,
  SamplePage,
  SearchTable,
  Tickets,
  Treeview,
  UserProfile,
  WidgetBanners,
  WidgetCards,
  WidgetCharts
} from './template';



const Login = Loadable(lazy(() => import('../views/authentication/auth/Login')));
const AuthOTP = Loadable(lazy(() => import('src/views/authentication/authForms/AuthOTP')));
// const SignUpAs = Loadable(lazy(() => import('src/views/authentication/auth/RequestType')));
const Register = Loadable(lazy(() => import('src/views/authentication/auth/Register')));
// const Home = Loadable(lazy(() => import('src/views/Home/HomePage')));
const Home = Loadable(lazy(() => import('src/views/HomePages/HomePage')));
//Exam worksheets
// const ExamWorkSheetList = Loadable(lazy(() => import('../views/exam/ExamWorkSheetList')));
// const ExamAttemptView = Loadable(lazy(() => import('../views/exam/ExamAttemptView')));
const QuillEditor = Loadable(lazy(() => import('src/components/editor/quillEditor/QuillEditor')));
const CKEditor = Loadable(lazy(() => import('src/components/editor/CKEditor')));
const AuthGuard = Loadable(lazy(() => import('src/guards/authGuard/AuthGuard')));
const GuestGuard = Loadable(lazy(() => import('src/guards/authGuard/GuestGaurd')));
const AllQuestion = Loadable(lazy(() => import('src/views/questionmanager/AllQuestion/AllQuestion')));
const CustomQuestion = Loadable(lazy(() => import('src/views/questionmanager/CustomQuestion/AllQuestion')));
const StandardManager = Loadable(lazy(() => import('src/views/questionmanager/Standard/StandardManager.js')));
const SubjectManager = Loadable(lazy(() => import('src/views/questionmanager/Subject/SubjectManager.js')));
const TopicManager = Loadable(lazy(() => import('src/views/questionmanager/topic/TopicManager')));
const QuestionNatureManager = Loadable(lazy(() => import('src/views/questionmanager/QuestionNature/QuestionNatureManager.js')));
// const PreviewScreen = Loadable(lazy(() => import('src/views/worksheet/formatting/PreviewPage.js')));
const EditScreenPaper = Loadable(lazy(() => import('src/views/testgenerator/formatting/EditPaperPage.js')));
// const ProductDetail = Loadable(lazy(() => import('src/views/worksheet/alltest/ProductDetail.js')));
const GeneralInsruction = Loadable(lazy(() => import('src/views/testmanager/examview/GeneralInstruction.js')));
const SolvePaper = Loadable(lazy(() => import('src/views/testmanager/examview/SolvePaper')));
const PaperPdf = Loadable(lazy(() => import('src/views/testgenerator/formatting/PaperPdf')));

const UpiScreen = Loadable(lazy(() => import('src/views/UPIManagement/UpiScreen')));

// const QuestionPaperAttemptPaper = Loadable(lazy(() => import('src/views/exam/AttemptPaper/QuestionPaper')));
const PaperAnalysis = Loadable(lazy(() => import('src/views/testmanager/PaperAnalysisview/AnalysisPage.js')));

const ProfileInformation = Loadable(lazy(() => import('src/views/usermanager/profileInformation/ProfileInformation')));
const ProfileDetail = Loadable(lazy(() => import('src/views/usermanager/profileInformation/ProfileInfoTab')));
const Referral = Loadable(lazy(() => import('src/views/usermanager/referral/ReferralTab')));
// const TakeReferral = Loadable(lazy(() => import('src/views/usermanager/referral/TakeRefrel')));
const TakeReferralCoins = Loadable(lazy(() => import('src/views/usermanager/referral/TakeReferralCoins')));
// const CoinPurchase = Loadable(lazy(() => import('src/views/usermanager/coinPurchase/CoinPurchaseTab')));
// const CoinHistory = Loadable(lazy(() => import('src/views/usermanager/coinPurchase/CoinHistoryTab')));
// const CoinTransfer = Loadable(lazy(() => import('src/views/usermanager/coinPurchase/CoinTransferTab')));

const QuestionDashboard = Loadable(lazy(() => import('src/views/Dashboard/DashboardPage')));
const Instruction = Loadable(lazy(() => import('src/views/testgenerator/Instruction/InstructionManager.js')));

const AnswerManager = Loadable(lazy(() => import('src/views/questionmanager/answer/AnswerManager')));
const BloomtaxonomyManager = Loadable(lazy(() => import('src/views/questionmanager/bloom/BloomtaxonomyManager')));
const BoardManager = Loadable(lazy(() => import('src/views/questionmanager/board/BoardManager.js')));
// const BookManager = Loadable(lazy(() => import('src/views/questionmanager/book/BookManager.js')));
const SubjectChapters = Loadable(lazy(() => import('src/views/questionmanager/SubjectChapter/SubjectChapter')));

const ChapterManager = Loadable(lazy(() => import('src/views/questionmanager/chapter/ChapterManager.js')));
const SourceManager = Loadable(lazy(() => import('src/views/questionmanager/Source/SourceManager.js')));
const SubTopicManager = Loadable(lazy(() => import('src/views/questionmanager/SubTopic/SubTopicManager.js')));
const TagsManager = Loadable(lazy(() => import('src/views/questionmanager/Tags/TagsManager.js')));

const BullEyes = Loadable(lazy(() => import("src/views/testmanager/bullseyeview/BullEyes")));
const BullEyesInstruction = Loadable(lazy(() => import("src/views/testmanager/bullseyeview/BullsEyeInstruction")));
const BullEyesAnalysis = Loadable(lazy(() => import("src/views/testmanager/bullseyeview/bullEyeAnalysis/BullEyePaperAnalysis")));

const PracticeInstruction = Loadable(lazy(() => import('src/views/testmanager/practiveview/PracticeInstruction')));
const McqTest = Loadable(lazy(() => import('src/views/testmanager/practiveview/McqTest')));
const Analytics = Loadable(lazy(() => import('src/views/testmanager/practiveview/Analytics')));

const PriceDistribution = Loadable(lazy(() => import('src/views/usermanager/PriceDistribution/PriceDistribution')));
const TestList = Loadable(lazy(() => import('src/views/testmanager/practiveview/TestList')));
// const InstructionsPage = Loadable(lazy(() => import('src/views/testmanager/pages/InstructionsPage')));
// const NextInstructionsPage = Loadable(lazy(() => import('src/views/testmanager/pages/NextInstructionPage')));
const AllUsers = Loadable(lazy(() => import('src/views/usermanager/user/AllUsers')));
const AllRoles = Loadable(lazy(() => import('src/views/usermanager/role/AllRole')));
const UserTransactions = Loadable(lazy(() => import('src/views/usermanager/UserTransactions/UserTransaction')));
const WalletScreen = Loadable(lazy(() => import('src/views/Wallet/WalletScreen')));
const Profile = Loadable(lazy(() => import('src/views/usermanager/Profile')));
const InstituteProfile = Loadable(lazy(() => import('src/views/usermanager/InstituteProfile')));
const Pricing = Loadable(lazy(() => import('../views/pricing/Pricing')));
const Faq = Loadable(lazy(() => import('../views/faq/Faq')));
const ReleaseNotes = Loadable(lazy(() => import('../views/releaseNotes/ReleaseNotes')));
// const LandingPage = Loadable(lazy(() => import('../views/LandingPage/LandingPage.js')));
const HighChart = Loadable(lazy(() => import('src/views/questionmanager/HighChart.js')));
const QuestionPicker = Loadable(lazy(() => import('src/views/testgenerator/cherrypick/Index')));
// const WorkSheetList = Loadable(lazy(() => import('src/views/testgenerator/workSheetListing')));
const WorkSheetFilterPage = Loadable(lazy(() => import('src/views/testgenerator/express/ExpressTestComponent')));
const TestGeneratorStepOne = Loadable(lazy(() => import('src/views/testgenerator/TestGenerator')));
const QuestionProfile = Loadable(lazy(() => import('src/views/questionmanager/AllQuestion/QuestionProfile')));
const CustomQuestionProfile = Loadable(lazy(() => import('src/views/questionmanager/CustomQuestion/QuestionProfile')));
const AllTests = Loadable(lazy(() => import('src/views/testmanager/alltest/AllTests')));
const AllTestSeriesTests = Loadable(lazy(() => import('src/views/testmanager/AllTestSeriesTests/AllTestSeriesTests')));

const Tests = Loadable(lazy(() => import('src/views/testmanager/alltest/Tests')));
const InstructionForSpacialPapers = Loadable(lazy(() => import('src/views/testmanager/Spacialpapers/InstructionForSpacialPapers')));
const AttemptPaper = Loadable(lazy(() => import('src/views/testmanager/Spacialpapers/AttemptPaper')));
const ThankYouPage = Loadable(lazy(() => import('src/views/testmanager/Spacialpapers/ThankYouPage')));
const AttemptedPapers = Loadable(lazy(() => import('src/views/testmanager/alltest/AttemptedPapers')));
const SpecialPapers = Loadable(lazy(() => import('src/views/testmanager/SpecialPaper/SpecialPaper')));
const SpecialLeaderBoard = Loadable(lazy(() => import('src/views/testmanager/SpecialPaper/SpecialLeaderBord.js')));
const SpecialPaperLeaderBoard = Loadable(lazy(() => import('src/views/testmanager/Spacialpapers/SpecialPaperLeaderBoard')));

// const AllTests = Loadable(lazy(() => import('src/views/testmanager/alltest/ExpressTable')));
const CherryPickTable = Loadable(lazy(() => import('src/views/testmanager/alltest/CherryPickTable')));
const ExpressTable = Loadable(lazy(() => import('src/views/testmanager/alltest/ExpressTable')));
const ExamPapers = Loadable(lazy(() => import('src/views/testmanager/alltest/ExamPapers')));
const PracticePapers = Loadable(lazy(() => import('src/views/testmanager/alltest/PracticePapers')));
const EditQuestionPaper = Loadable(lazy(() => import('src/views/testmanager/alltest/QuestionInfoPage')));
const CYOT = Loadable(lazy(() => import('src/views/testmanager/CYOT')));
const SpecialTest = Loadable(lazy(() => import('src/views/testmanager/SpecialTest')));

const AllNotes = Loadable(lazy(() => import('src/views/Notes/AllNotes/AllNotes')));
const TeacherNotes = Loadable(lazy(() => import('src/views/Notes/TeacherNotes/TeacherAllNotes')));
const NoteDetailPage = Loadable(lazy(() => import('src/views/Notes/AllNotes/NoteDetailPage')));

const CreateTestSeries = Loadable(lazy(() => import('src/views/TestSeries/CreateTestSeries/CreateTestSeries')));
const EditTestSeries = Loadable(lazy(() => import('src/views/TestSeries/EditTestSeries/EditTestSeries')));
const TeacherAllTestSeries = Loadable(lazy(() => import('src/views/TestSeries/AllTestSeries/TeacherAllTestSeries')));
const TestSeriesPageView = Loadable(lazy(() => import('src/views/TestSeries/PerSeriesView/TestSeriesPageView')));

const BatchPage = Loadable(lazy(() => import('src/views/batchmode/BatchPage')));
const BatchContent = Loadable(lazy(() => import('src/views/batchmode/BatchContent')));

const OverallLeaderBoard = Loadable(lazy(() => import('src/components/Leaderboard/OverallLeaderBoard')));

const AdminDashboardPage = Loadable(lazy(() => import('src/views/AdminDashboard/AdminDashboardPage')));

const CreateTournament = Loadable(lazy(() => import('src/views/Tournament/CreateTournament/CreateTornament')));

const Tournament = Loadable(lazy(() => import('src/views/Tournament/TournamentShow/Tournament')));
const AttemptedTournaments = Loadable(lazy(() => import('src/views/Tournament/TournamentShow/AttemptedTournaments')));
const TournamentInfo = Loadable(lazy(() => import('src/views/Tournament/TournamentShow/TornamnetInfo')));
const TournamentInstruction = Loadable(lazy(() => import('src/views/Tournament/AttemptFlow/Instruction/Instruction.js')));
const TournamentAttempt = Loadable(lazy(() => import('src/views/Tournament/AttemptFlow/TournamentAttempt.js')));


const CreateNotifications = Loadable(lazy(() => import('src/views/AdminFunctions/notifications/CreateNotifications.js')));
const AllUsersFeedbackDetails = Loadable(lazy(() => import('src/views/AdminFunctions/FeedbackDetails/AllUsersFeedbackDetails')));

const AllUsersCashDetails = Loadable(lazy(() => import('src/views/usermanager/CashDetails/AllUsersCashDetails')));

const Router = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      // question_manager
      { path: '/', element: <Home /> },
      { path: '/question-manager/dashboard', element: <QuestionDashboard /> },
      { path: '/question-manager/question', element: <AllQuestion /> },
      { path: '/question-manager/customQuestion', element: <CustomQuestion /> },

      { path: '/question-manager/question/:id', element: <QuestionProfile /> },
      { path: '/question-manager/customQuestion/:id', element: <CustomQuestionProfile /> },
      { path: '/question-manager/all-standard', element: <StandardManager /> },
      { path: '/question-manager/all-subject', element: <SubjectManager /> },
      { path: '/question-manager/all-blooms', element: <BloomtaxonomyManager /> },
      { path: '/question-manager/all-board', element: <BoardManager /> },
      { path: '/question-manager/all-chapter', element: <SubjectChapters /> },
      { path: '/question-manager/all-source', element: <SourceManager /> },
      { path: '/question-manager/all-tags', element: <TagsManager /> },
      { path: '/question-manager/all-questionNature', element: <QuestionNatureManager /> },

      // user_manager
      { path: '/user-manager/all-user', element: <AllUsers /> },
      { path: '/user-manager/all-role', element: <AllRoles /> },
      { path: '/user-manager/cashDetails', element: <AllUsersCashDetails /> },
      { path: '/user/UserTransactions', element: <UserTransactions /> },
      { path: '/user/profileinformation', element: <ProfileInformation /> },
      { path: '/user/ProfileDetails', element: <ProfileDetail /> },

      { path: '/user/coinPurchase', element: <WalletScreen /> },
      // { path: '/user/CoinHistory', element: <CoinHistory /> },
      // { path: '/user/CoinTransfer', element: <CoinTransfer /> },

      { path: '/price-distribution', element: <PriceDistribution /> },

      { path: '/user/referral', element: <Referral /> },
      { path: '/user/getreferral', element: <TakeReferralCoins /> },
      { path: '/claim', element: <UpiScreen /> },

      //Test Generator
      // Test Manager start
      { path: '/test-generator/cyot', element: <CYOT /> },
      { path: '/test-generator/special-test', element: <SpecialTest /> },

      { path: '/test-generator/express', element: <WorkSheetFilterPage /> },
      { path: '/test-generator/cherry-pick', element: <QuestionPicker /> },

      { path: '/allTestSeriesTests', element: <AllTestSeriesTests /> },
      { path: '/TestSeries/Create', element: <CreateTestSeries /> },
      { path: '/TestSeries/Edit/:id', element: <EditTestSeries /> },
      { path: '/YourAllCreatedTestSeries', element: <TeacherAllTestSeries /> },
      { path: '/test-series/:id', element: <TestSeriesPageView /> },

      { path: '/Instruction', element: <Instruction /> },

      { path: '/OverallLeaderBoard', element: <OverallLeaderBoard /> },

      // Tournament 
      { path: '/CreateTournament', element: <CreateTournament /> },
      { path: '/Tournament', element: <Tournament /> },
      { path: '/AttemptedTournament', element: <AttemptedTournaments /> },
      { path: '/TournamentInfo/:id', element: <TournamentInfo /> },
      { path: '/TournamentInstruction/:id', element: <TournamentInstruction /> },
      { path: '/TournamentAttempt', element: <TournamentAttempt /> },


      { path: '/wallet', element: <WalletScreen /> }

    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/', element: <Home /> },
      { path: '/user-manager/user-profile', element: <Profile /> },
      { path: '/user-manager/institute-profile', element: <InstituteProfile /> },
      // { path: '/welcome', element: <LandingPage />, feature: 'question_manager' },

      { path: '/Profile/Popup', element: <ProfileFormPopup />, feature: 'question_manager' },


      // Papers start
      { path: '/papers', element: <Tests /> },
      { path: '/attempted', element: <AttemptedPapers /> },
      { path: '/special', element: <SpecialPapers /> },
      { path: '/special_leaderbord/:id', element: <SpecialLeaderBoard /> },



      { path: '/all-papers', element: <AllTests /> },
      { path: '/cherrypick-papers', element: <CherryPickTable /> },
      { path: '/test-info/:id', element: <EditQuestionPaper /> },
      { path: '/express-papers', element: <ExpressTable /> },
      { path: '/exam-papers', element: <ExamPapers /> },
      { path: '/practice-papers', element: <PracticePapers /> },
      { path: '/paper/:mode/:id', element: <EditScreenPaper /> },
      // Papers end

      // Batch Mode 
      { path: '/batches', element: <BatchPage /> },
      { path: '/batchcontent/:batchID', element: <BatchContent /> },

      // Admin Dashboard 
      { path: '/admindashboard', element: <AdminDashboardPage /> },


      // Test Manager end


      //Question Add Page
      { path: '/test/Question/PracticeInstruction/:id', element: <PracticeInstruction /> },

      { path: '/test/Question/:id', element: <McqTest /> },
      { path: '/test/Question/analytics', element: <Analytics /> },

      { path: '/practice/test', element: <TestList /> },
      { path: '/tables/Sub/Topic/Manager', element: <SubTopicManager /> },
      { path: '/practice/Bulls-Eye-instruction/:duration/:question/:id', element: <BullEyesInstruction /> },
      { path: '/practice/Bulls-Eye/:duration/:question/:id', element: <BullEyes /> },
      { path: '/practice/Bulls-Eye-analysis/:totalQuestion/:paperId/:attemptId', element: <BullEyesAnalysis /> },


      { path: '/notes', element: <AllNotes /> },
      { path: '/TacherNotes', element: <TeacherNotes /> },
      { path: '/note/detail/:id', element: <NoteDetailPage /> },



      // { path: '/test/instruction', element: <InstructionsPage /> },
      // { path: '/test/Nextinstruction', element: <NextInstructionsPage /> },
      //Profile pages
      { path: '/quill-editor', element: <QuillEditor /> },
      { path: '/ck-editor', element: <CKEditor /> },
      // Exam WorkSheet
      // { path: '/exam-worksheets', element: <ExamWorkSheetList /> },
      { path: '/general-instruction/:id', element: <GeneralInsruction /> },
      { path: '/solve-paper/:id', element: <SolvePaper /> },


      // { path: '/exam-attempt', element: <ExamAttemptView /> },
      { path: '/test-generator/generator-mode', element: <TestGeneratorStepOne /> },
      // { path: '/test-preview/:id', element: <PreviewScreen /> },

      { path: '/paper-pdf/:id', element: <PaperPdf /> },
      // QuestionPaperAttemptPaper
      // { path: '/question-paper/:id', element: <QuestionPaperAttemptPaper /> },
      { path: '/paper-analysis/:id/:id', element: <PaperAnalysis /> },
      { path: '/paper-analysis/tournament/:id/:id', element: <PaperAnalysis /> },

      //Managment pages 

      { path: '/dashboards/modern', exact: true, element: <ModernDash /> },
      { path: '/pages/faq', element: <Faq /> },
      { path: '/pages/release-notes', element: <ReleaseNotes /> },
      { path: '/sample-page', exact: true, element: <SamplePage /> },
      // { path: '/worksheet/list', element: <WorkSheetList /> },

      // admin functions 
      { path: '/Notifications', exact: true, element: <CreateNotifications /> },
      { path: '/userFeedbacks', exact: true, element: <AllUsersFeedbackDetails /> },

      { path: '*', element: <Navigate to="/404" /> },

      {
        path: '/dashboards/ecommerce',
        exact: true,
        element: <EcommerceDash />,
      },
      { path: '/apps/chats', element: <Chats /> },
      { path: '/apps/notes', element: <Notes /> },
      { path: '/apps/calendar', element: <Calendar /> },
      { path: '/apps/email', element: <Email /> },
      { path: '/apps/tickets', element: <Tickets /> },
      { path: '/apps/contacts', element: <Contacts /> },
      { path: '/apps/ecommerce/shop', element: <Ecommerce /> },
      { path: '/apps/blog/posts', element: <Blog /> },
      { path: '/apps/blog/detail/:id', element: <BlogDetail /> },
      {
        path: '/apps/ecommerce/eco-product-list',
        element: <EcomProductList />,
      },
      {
        path: '/apps/ecommerce/eco-checkout',
        element: <EcomProductCheckout />,
      },
      { path: '/apps/ecommerce/detail/:id', element: <EcommerceDetail /> },
      { path: '/apps/followers', element: <Followers /> },
      { path: '/apps/friends', element: <Friends /> },
      { path: '/apps/gallery', element: <Gallery /> },
      { path: '/user-profile', element: <UserProfile /> },
      { path: '/pages/casl', element: <RollbaseCASL /> },
      { path: '/pages/treeview', element: <Treeview /> },
      { path: '/pages/pricing', element: <Pricing /> },
      { path: '/pages/account-settings', element: <AccountSetting /> },

      { path: '/forms/form-elements/autocomplete', element: <MuiAutoComplete /> },
      { path: '/forms/form-elements/button', element: <MuiButton /> },
      { path: '/forms/form-elements/checkbox', element: <MuiCheckbox /> },
      { path: '/forms/form-elements/radio', element: <MuiRadio /> },
      { path: '/forms/form-elements/slider', element: <MuiSlider /> },
      { path: '/forms/form-elements/date-time', element: <MuiDateTime /> },
      { path: '/forms/form-elements/switch', element: <MuiSwitch /> },
      { path: '/forms/form-elements/switch', element: <MuiSwitch /> },

      { path: '/forms/form-layouts', element: <FormLayouts /> },
      { path: '/forms/form-horizontal', element: <FormHorizontal /> },
      { path: '/forms/form-vertical', element: <FormVertical /> },
      { path: '/forms/form-custom', element: <FormCustom /> },
      { path: '/forms/form-wizard', element: <FormWizard /> },
      { path: '/forms/form-validation', element: <FormValidation /> },
      { path: '/tables/basic', element: <BasicTable /> },
      { path: '/tables/collapsible', element: <CollapsibleTable /> },
      { path: '/tables/enhanced', element: <EnhancedTable /> },
      { path: '/tables/fixed-header', element: <FixedHeaderTable /> },
      { path: '/tables/pagination', element: <PaginationTable /> },
      { path: '/tables/search', element: <SearchTable /> },
      { path: '/charts/line-chart', element: <LineChart /> },
      { path: '/charts/gredient-chart', element: <GredientChart /> },
      { path: '/charts/doughnut-pie-chart', element: <DoughnutChart /> },
      { path: '/charts/area-chart', element: <AreaChart /> },
      { path: '/charts/column-chart', element: <ColumnChart /> },
      { path: '/charts/candlestick-chart', element: <CandlestickChart /> },
      { path: '/charts/radialbar-chart', element: <RadialbarChart /> },
      { path: '/ui-components/alert', element: <MuiAlert /> },
      { path: '/ui-components/accordion', element: <MuiAccordion /> },
      { path: '/ui-components/avatar', element: <MuiAvatar /> },
      { path: '/ui-components/chip', element: <MuiChip /> },
      { path: '/ui-components/dialog', element: <MuiDialog /> },
      { path: '/ui-components/list', element: <MuiList /> },
      { path: '/ui-components/popover', element: <MuiPopover /> },
      { path: '/ui-components/rating', element: <MuiRating /> },
      { path: '/ui-components/tabs', element: <MuiTabs /> },
      { path: '/ui-components/tooltip', element: <MuiTooltip /> },
      { path: '/ui-components/transfer-list', element: <MuiTransferList /> },
      { path: '/ui-components/typography', element: <MuiTypography /> },
      { path: '/widgets/cards', element: <WidgetCards /> },
      { path: '/widgets/banners', element: <WidgetBanners /> },
      { path: '/widgets/charts', element: <WidgetCharts /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'maintenance', element: <Maintenance /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/specialtest',
    element: <BlankLayout />,
    children: [
      { path: '/specialtest/:id', element: <InstructionForSpacialPapers /> },
      { path: '/specialtest/attempt/:id', element: <AttemptPaper /> },
      { path: '/specialtest/complision', element: <ThankYouPage /> },
      { path: '/specialtest/special_leaderbord/:id', element: <SpecialPaperLeaderBoard /> },
    ],
  },
  {
    path: '/auth',
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard>
    ),
    children: [
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/otp-validation', element: <AuthOTP /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  }
]


export default Router;
