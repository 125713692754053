import { Chance } from 'chance';
import { uniqueId } from 'lodash';

import mock from './mock';

const chance = new Chance();

const questions = [
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Mars", "Venus", "Mercury", "Jupiter"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["London", "Paris", "Rome", "Madrid"],
		"answer": chance.integer({ min: 1, max: 4 }),

	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Leonardo da Vinci", "Pablo Picasso", "Vincent van Gogh", "Claude Monet"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Elephant", "Tiger", "Lion", "Giraffe"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Au", "Ag", "Gd", "Go"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["China", "India", "Japan", "Brazil"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Heart", "Liver", "Lung", "Skin"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["William Shakespeare", "Jane Austen", "Charles Dickens", "Mark Twain"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["12", "14", "16", "18"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Mount Everest", "Kilimanjaro", "Mount McKinley", "Mount Fuji"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["France", "Brazil", "Germany", "Argentina"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Indian Ocean", "Arctic Ocean", "Pacific Ocean", "Atlantic Ocean"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Alexander Fleming", "Thomas Edison", "Albert Einstein", "Isaac Newton"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Thermometer", "Barometer", "Hydrometer", "Anemometer"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["New York City", "Chicago", "Los Angeles", "Houston"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Ox", "Oi", "Oxg", "O"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Buzz Aldrin", "Neil Armstrong", "Yuri Gagarin", "Alan Shepard"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Beijing", "Tokyo", "Seoul", "Osaka"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Mars", "Venus", "Jupiter", "Saturn"],
		"answer": chance.integer({ min: 1, max: 4 }),
	},
	{
		"id": uniqueId('#comm_'),
		"question": chance.paragraph({ sentences: 3 }),
		"options": ["Leonardo da Vinci", "Pablo Picasso", "Vincent van Gogh", "Michelangelo"],
		"answer": chance.integer({ min: 1, max: 4 }),
	}

]
const workSheet = [
	{
		section_name: "Section1",
		id: uniqueId('#comm_'),
		questions: questions
	},
	{
		section_name: "Section2",
		id: uniqueId('#comm_'),
		questions: questions
	},
	{
		section_name: "Section3",
		id: uniqueId('#comm_'),
		questions: questions
	}
]

mock.onGet('/api/worksheet').reply(() => {
	return [200, workSheet];
});
